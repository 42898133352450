import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { withRouter, RouteComponentProps } from "react-router-dom";
import * as _ from "lodash";
import { scwApi } from "../../services/Api";
import { Button, Input, InputGroup, InputGroupText } from "reactstrap";
import Icon from "@material-ui/core/Icon";
import PanelSearchResult from "./PanelSearchResult";
import { IndicatorDTO } from "../../services/models/IndicatorDTO";

import "./PanelSearch.css";


type PanelSearchProps = RouteComponentProps<{}> &  {
    dataIndicatorsSearch: any,
    locale: string[2],
    scwApiIndicatorsSearch: Function,
};

type PanelSearchState = {
    locale: string[2],
    isSearchResultVisible: boolean,
    searchResult: IndicatorDTO[],
    searchText: string,
}

class PanelSearch extends React.Component<PanelSearchProps, PanelSearchState> {

    inputRef: any;

    constructor(props: Readonly<PanelSearchProps>) {
        super(props);

        this.inputRef = null;

        this.state = {
            locale: "",
            isSearchResultVisible: false,
            searchResult: [],
            searchText: "",
        };
    } // constructor

    static getDerivedStateFromProps(nextProps: Readonly<PanelSearchProps>, prevState: PanelSearchState):
        Partial<PanelSearchState> | null {
        const loSearchResult = nextProps.dataIndicatorsSearch(prevState.searchText).data;

        const hasSearchResultChanged = !_.isEqual(loSearchResult, prevState.searchResult);

        if ((!hasSearchResultChanged) && (nextProps.locale === prevState.locale))
            return null;

        return {
            locale: nextProps.locale,
            isSearchResultVisible: hasSearchResultChanged,
            searchResult: loSearchResult,
            searchText: prevState.searchText,
        }
    } // getDerivedStateFromProps

    onInputBlur(e: any) {
        // console.log('(PanelSearch.onInputBlur)', e);

        // const loState: PanelSearchState = _.assign({}, this.state);
        // loState.isSearchResultVisible = false;

        // this.setState(loState);
    } // onInputBlur

    onInputChange(e: any) {
        const loState: PanelSearchState = _.assign({}, this.state);
        loState.searchText = e.target.value;

        this.setState(loState);
    } // onInputChange

    onInputKeyDown(e: any) {
        if (e.keyCode == 13)
            this.onSearchClick();
    } // onInputKeyDown

    onSearchClick() {
        if (_.isEmpty(this.state.searchText))
            return;

        const loState: PanelSearchState = _.assign({}, this.state);
        loState.searchText = this.state.searchText;
        loState.isSearchResultVisible = true;

        if (!_.isNil(this.inputRef))
            this.inputRef.focus();

        this.setState(
            loState,
            () => {
                this.props.scwApiIndicatorsSearch(this.props.locale, this.state.searchText);
            }
        );
    } // onSearchClick

    render() {
        return (
            <div className="search-panel-container" id="panel-search">
                <div className="search-panel-title">
                    <span>
                        {I18n.t("frontend_panel_search_title")}
                    </span>
                </div>
                <div className="search-panel-input">
                    <InputGroup>
                        <InputGroupText>
                            <Icon>search</Icon>
                        </InputGroupText>
                        <Input
                            ref={(aoInput: any) => { this.inputRef = aoInput; }}
                            autoComplete="off"
                            autoCorrect="off"
                            autoCapitalize="off"
                            id="panel-search-input"
                            placeholder={I18n.t("frontend_panel_search_input_placeholder")}
                            spellCheck={false}
                            type="search"
                            value={this.state.searchText}
                            onBlur={(e) => { this.onInputBlur(e); }}
                            onChange={(e) => { this.onInputChange(e); }}
                            onKeyDown={(e) => { this.onInputKeyDown(e); }}
                        />
                        <div className="search-panel-input-container-button">
                            <Button onClick={() => this.onSearchClick()} onBlur={(e: any) => { this.onInputBlur(e); }}>
                                {I18n.t("frontend_panel_search_button_search")}
                            </Button>
                        </div>
                    </InputGroup>
                    <PanelSearchResult isVisible={this.state.isSearchResultVisible} searchResult={this.state.searchResult} />
                </div>
                <div className="search-panel-input-hint">
                    <span>
                        {I18n.t("frontend_panel_search_input_hint")}
                    </span>
                </div>
            </div>
        );
    } // render

}

// Link with Redux store

const mapStateToProps =
    (state: any) => ({
        locale: state.i18n.locale,
        dataIndicatorsSearch: (searchText: string) => scwApi.endpoints.indicatorsSearch.select({ "languageCode": state.i18n.locale, "searchText": searchText })(state)
    });

const mapDispatchToProps =
    (dispatch: Function) => {
        return {
            scwApiIndicatorsSearch:
                (languageCode: string, searchText: string) =>
                    dispatch(scwApi.endpoints.indicatorsSearch.initiate({ languageCode: languageCode, searchText: searchText })),
        }
    };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PanelSearch));