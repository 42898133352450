import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import * as _ from "lodash";
import { scwApi } from "../../services/Api";
import { IndicatorDTO } from "../../services/models/IndicatorDTO";
import { IndicatorTypeDTO } from "../../services/models/IndicatorTypeDTO";
import ButtonCustomGroup from "../ButtonCustomGroup";
import PanelIndicatorTile from "./PanelIndicatorTile";
import LinkSee from "../LinkSee";
import "./PanelFrequentlyVisited.css";


type PanelFrequentlyVisitedProps = {
    dataIndicatorsGetFrequentlyVisited: Function,
    dataIndicatorsGetTypes: any,
    locale: string[2],
    scwApiIndicatorsGetFrequentlyVisited: Function,
    scwApiIndicatorsGetTypes: Function,
};

type PanelFrequentlyVisitedState = {
    indicators: IndicatorDTO[],
    locale: string[2],
    selectedType: IndicatorTypeDTO | null
    types: IndicatorTypeDTO[] | [null] | null,
}

class PanelFrequentlyVisited extends React.Component<PanelFrequentlyVisitedProps, PanelFrequentlyVisitedState> {

    constructor(props: Readonly<PanelFrequentlyVisitedProps>) {
        super(props);

        this.state = {
            indicators: [],
            locale: props.locale,
            types: null,
            selectedType: null,
        }
    } // constructor

    static getDerivedStateFromProps(nextProps: Readonly<PanelFrequentlyVisitedProps>, prevState: PanelFrequentlyVisitedState):
        Partial<PanelFrequentlyVisitedState> | null {

        if (
            !nextProps.dataIndicatorsGetTypes.isLoading
                && !nextProps.dataIndicatorsGetTypes.isSuccess
        )
            nextProps.scwApiIndicatorsGetTypes(nextProps.locale);

        if (
            !nextProps.dataIndicatorsGetFrequentlyVisited(nextProps.locale).isSuccess &&
                !nextProps.dataIndicatorsGetFrequentlyVisited(nextProps.locale).isLoading
        )
            nextProps.scwApiIndicatorsGetFrequentlyVisited(nextProps.locale);

        return {
            indicators: (
                nextProps.dataIndicatorsGetFrequentlyVisited(nextProps.locale).isSuccess
                    ? _.take(
                        _.filter(
                            nextProps.dataIndicatorsGetFrequentlyVisited(nextProps.locale).data,
                            (aoIndicator: IndicatorDTO) => {
                                return (
                                    _.isNull(prevState.selectedType) ||
                                        (aoIndicator.indicatorTypeID === prevState.selectedType.indicatorTypeID)
                                );
                            }
                        ),
                        6
                    )
                    : []
            ),
            locale: nextProps.locale,
            types: (
                nextProps.dataIndicatorsGetTypes.isSuccess
                    ? nextProps.dataIndicatorsGetTypes.data
                    : null
            ),
        }
    } // getDerivedStateFromProps

    onTypeGetCaption(aoObject: IndicatorTypeDTO | null) {
        return (_.isNil(aoObject) ? I18n.t("frontend_text_all") : aoObject.indicatorTypeName);
    } // onTypeGetCaption

    onTypeSelected(aoObject: IndicatorTypeDTO | null) {
        this.setState({
            selectedType: aoObject
        });
    } // onTypeSelected

    render() {
        return (
            <div className="panel-frequently-visited">
                <div className="panel-title">
                    <div className="title">{I18n.t("frontend_frequently_visited_panel_title")}</div>

                    <div className="type-selection">
                        <ButtonCustomGroup
                            objects={_.isNil(this.state.types) ? [] : _.concat(null, this.state.types)}
                            onGetCaption={ (aoObject: any) => this.onTypeGetCaption(aoObject) }
                            onSelected={ (aoObject: any) => this.onTypeSelected(aoObject) }
                            selectedObject={this.state.selectedType}
                        />
                    </div>
                </div>
                <ul>
                    {
                        this.state.indicators.map(
                            aoIndicator => {
                                return (
                                    <li key={aoIndicator.indicatorID + "_" + aoIndicator.name}>
                                        <PanelIndicatorTile
                                            indicator={aoIndicator}
                                            renderCircleShape={false} />
                                    </li>
                                );
                            }
                        )
                    }
                    {/*<li className="row-link-see-all">*/}
                    {/*    <LinkSee mode="all"/>*/}
                    {/*</li>*/}
                </ul>
            </div>
        );
    } // render
}

// Link with Redux store

const mapStateToProps =
    (state: any) => (
        {
            locale: state.i18n.locale,
            dataIndicatorsGetFrequentlyVisited: (languageCode: string) => scwApi.endpoints.indicatorsGetFrequentlyVisited.select(languageCode)(state),
            dataIndicatorsGetTypes: scwApi.endpoints.indicatorsGetTypes.select(state.i18n.locale)(state),
        }
    );

const mapDispatchToProps =
    (dispatch: Function) => {
        return {
            scwApiIndicatorsGetFrequentlyVisited: (languageCode: string) => dispatch(scwApi.endpoints.indicatorsGetFrequentlyVisited.initiate(languageCode)),
            scwApiIndicatorsGetTypes: (languageCode: string) => dispatch(scwApi.endpoints.indicatorsGetTypes.initiate(languageCode)),
        }
    };

export default connect(mapStateToProps, mapDispatchToProps)(PanelFrequentlyVisited);