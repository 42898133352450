import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import * as _ from "lodash";
import { scwApi } from "../../services/Api";
import { Table } from "reactstrap";
import ButtonCustomGroup from "../ButtonCustomGroup";
import { LanguageMetadataDTO } from "../../services/models/LanguageMetadataDTO";
import { IndicatorDTO } from "../../services/models/IndicatorDTO";

import "./PanelMetadata.css";


type PanelMetadataProps = {
    dataIndicatorsGetMetadata: any;
    indicators: IndicatorDTO[];
    indicatorID: number;
    locale: string[2];
    scwApiIndicatorsGetMetadata: Function;
}

type PanelMetadataState = {
    selectedGroup: any,
    selectedIndicatorID?: number,
}


class PanelMetadata extends React.Component<PanelMetadataProps, PanelMetadataState> {

    groups: any[] = [
        {
            captionLanguageKey: "frontend_metadata_panel_group_summary_information",
            items: [
                {
                    itemCaptionLanguageKey: "frontend_metadata_panel_general_description",
                    itemValueLanguageKey: "dc:title",
                },
                {
                    itemCaptionLanguageKey: "frontend_metadata_panel_data_collection_and_calculation",
                    itemValueLanguageKey: "dc:description",
                },
                {
                    itemCaptionLanguageKey: "frontend_metadata_panel_frequency_of_publication",
                    itemValueLanguageKey: "Frequencyofpublication",
                },
                {
                    itemCaptionLanguageKey: "frontend_metadata_panel_revisions_information",
                    itemValueLanguageKey: "dc:coverage.temporal",
                },
            ],
            name: "summaryInformation",
        },
        {
            captionLanguageKey: "frontend_metadata_panel_group_keywords",
            items: [
                {
                    itemCaptionLanguageKey: "",
                    itemValueLanguageKey: "dc:subject.keyword",
                },
            ],
            name: "keywords",
        },
        {
            captionLanguageKey: "frontend_metadata_panel_group_weblinks",
            items: [
                {
                    itemCaptionLanguageKey: "frontend_metadata_panel_portal_link",
                    itemValueLanguageKey: "scw.PortalLink",
                },
                {
                    itemCaptionLanguageKey: "frontend_metadata_panel_source_link",
                    itemValueLanguageKey: "scw.SourceLink",
                },
            ],
            name: "weblinks",
        },
        {
            captionLanguageKey: "frontend_metadata_panel_group_data_quality",
            items: [],
            name: "dataQuality",
        },
        {
            captionLanguageKey: "frontend_metadata_panel_group_open_data",
            items: [
                {
                    itemCaptionLanguageKey: "frontend_metadata_panel_rights",
                    itemValueLanguageKey: "dc:rights",
                },
            ],
            name: "openData",
        }
    ];

    constructor(props: Readonly<any>) {
        super(props);

        this.state = {
            selectedGroup: this.groups[0],
            selectedIndicatorID: undefined,
        }
    } // constructor

    static getDerivedStateFromProps(nextProps: Readonly<PanelMetadataProps>, prevState: PanelMetadataState):
        Partial<PanelMetadataState> | null {

        let nextStateSelectedIndicatorID = prevState.selectedIndicatorID;
        if (_.isNil(nextStateSelectedIndicatorID)) {
            nextStateSelectedIndicatorID = nextProps.indicatorID;
        }

        const loScwApiObject = nextProps.dataIndicatorsGetMetadata(nextProps.locale, nextStateSelectedIndicatorID);
        if (!loScwApiObject.isSuccess && !loScwApiObject.isLoading)
            nextProps.scwApiIndicatorsGetMetadata(nextProps.locale, nextStateSelectedIndicatorID);

        return {
            selectedIndicatorID: nextStateSelectedIndicatorID,
        };
    } // getDerivedStateFromProps

    getMetadataItemValueArr(aoItem: any): string[] {
        if (_.isNil(this.state.selectedIndicatorID))
            return [];

        const loScwApiObject = this.props.dataIndicatorsGetMetadata(this.props.locale, this.state.selectedIndicatorID);

        if (!loScwApiObject.isSuccess)
            return [];

        return _.filter<LanguageMetadataDTO>(
            loScwApiObject.data,
            aoScwApiObjectItem => (aoScwApiObjectItem.key === aoItem.itemValueLanguageKey)
        ).map(
            aoScwApiObjectItem => aoScwApiObjectItem.value.split("[").join("<").split("]").join(">")
        );
    } // getMetadataItemValueArr

    onGroupGetCaption(aoObject: any) {
        return I18n.t(aoObject.captionLanguageKey);
    } // onGroupGetCaption

    onGroupSelected(aoObject: any) {
        this.setState({
            selectedGroup: aoObject
        });
    } // onGroupSelected

    onSelectedIndicatorChange(aiIndicatorID: number) {
        this.setState(
            prevState => ({
                ...prevState,
                selectedIndicatorID: aiIndicatorID,
            })
        );
    } // onSelectedIndicatorChange

    render() {
        return (
            <>
                <Table borderless className="panel-metadata">
                    <tbody>
                    <tr>
                        <td className="panel-title">
                            {I18n.t("frontend_metadata_panel_title")}
                        </td>
                        <td className="panel-metadata-cell-group-selection">
                            <ButtonCustomGroup
                                objects={this.groups}
                                onGetCaption={(aoObject: any) => this.onGroupGetCaption(aoObject)}
                                onSelected={(aoObject: any) => this.onGroupSelected(aoObject)}
                                selectedObject={this.state.selectedGroup}
                            />
                        </td>
                    </tr>
                        {
                        (this.props.indicators.length > 1) && (
                            <tr>
                                <td className="panel-metadata-item-cell-title border-bottom">
                                    {I18n.t("frontend_text_measure")}
                                </td>
                                    <td className="panel-metadata-item-cell-description border-bottom">
                                        <select
                                            defaultValue={this.state.selectedIndicatorID}
                                            onChange={(e) => this.onSelectedIndicatorChange(parseInt(e.target.value))}
                                            style={{ 'width': '100%' }}>
                                            {
                                                this.props.indicators.map(
                                                    aoIndicator => (
                                                        <option
                                                            value={aoIndicator.indicatorID}
                                                            key={aoIndicator.indicatorID}>{aoIndicator.name}</option>
                                                    )
                                                )
                                            }
                                    </select>
                                </td>
                                </tr>
                        )
                    }
                    {
                            (this.state.selectedGroup.name === "dataQuality") && (
                                <tr key={this.state.selectedGroup.name}>
                                    <td className={`panel-metadata-item-cell-title`}>
                                    </td>
                                    <td className={`panel-metadata-item-cell-description`}>
                                        {I18n.t("frontend_text_metadata_data_quality_in_development")}
                                    </td>
                                </tr>
                            )
                    }
                    {
                            (this.state.selectedGroup.name !== "dataQuality") && this.state.selectedGroup.items.map(
                                (aoItem: any, aiIndex: number) => {
                                    const lbIsLast = (this.state.selectedGroup.items.length === aiIndex + 1);

                                    return (
                                        <tr key={this.state.selectedGroup.name + "_" + aoItem.itemCaptionLanguageKey}>
                                            <td className={`panel-metadata-item-cell-title${(!lbIsLast && " border-bottom" || "")}`}>
                                                {I18n.t(aoItem.itemCaptionLanguageKey)}
                                            </td>
                                            <td className={`panel-metadata-item-cell-description${(!lbIsLast && " border-bottom" || "")}`}>
                                                <div dangerouslySetInnerHTML={{ __html: this.getMetadataItemValueArr(aoItem).join(", ") }}>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                }
                            )
                    }
                    </tbody>
                </Table>
            </>
        );
    } // render
}

// Link with Redux store

const mapStateToProps =
    (state: any) => (
        {
            locale: state.i18n.locale,
            dataIndicatorsGetMetadata:
                (languageCode: string, id: number) => scwApi.endpoints.indicatorsGetMetadata.select({ "languageCode": languageCode, "id": id })(state)
        }
    );

const mapDispatchToProps =
    (dispatch: Function) => {
        return {
            scwApiIndicatorsGetMetadata:
                (languageCode: string, id: number) =>
                    dispatch(scwApi.endpoints.indicatorsGetMetadata.initiate({ languageCode: languageCode, id: id })),
        }
    };

export default connect(mapStateToProps, mapDispatchToProps)(PanelMetadata);