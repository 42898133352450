import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import * as _ from "lodash";
import "./LinkSee.css";


type LinkSeeProps = {
    locale: string[2],
    mode?: string,
    onClick?: Function,
};

type LinkSeeState = {

}

class LinkSee extends React.Component<LinkSeeProps, LinkSeeState> {

    onClick() {
        if (_.isNil(this.props.onClick))
            return;

        this.props.onClick(this.props.mode);
    } // onClick

    render() {
        return (
            <div className="link-see" onClick={() => this.onClick()}>
                {
                    (this.props.mode === "all") &&
                    (
                        <span>
                            {I18n.t("frontend_text_see_all")}
                            <span className="chevron right"></span>
                        </span>
                    )
                }
                {
                    (this.props.mode === "more") &&
                    (
                        <span>
                            {I18n.t("frontend_text_see_more")}
                            <span className="chevron"></span>
                        </span>
                    )
                }
                {

                    (this.props.mode === "less") &&
                    (
                        <span>
                            {I18n.t("frontend_text_see_less")}
                            <span className="chevron"></span>
                        </span>
                    )
                    
                }
            </div>
        );
    } // render
}

// Link with Redux store

const mapStateToProps =
    (state: any) => (
        {
            locale: state.i18n.locale,
        }
    );

export default connect(mapStateToProps)(LinkSee);