import * as React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import _ from "lodash";
import Heading from "../Heading";
import PanelDataCategory from "../panel/PanelDataCategory";
import PanelFrequentlyVisited from "../panel/PanelFrequentlyVisited";
import PanelMainDataCategories from "../panel/PanelMainDataCategories";
import PanelInfographics from "../panel/PanelInfographics";
import PanelSearch from "../panel/PanelSearch";
import PanelUpdates from "../panel/PanelUpdates";
import { CategoryHierarchyDTO } from "../../services/models/CategoryHierarchyDTO";
import "./PageHome.css";


class PageHome extends React.Component<any, any> {

    onPanelMainDataCategoriesRenderCategory = (aoCategory: CategoryHierarchyDTO): JSX.Element => {
        return (
            <PanelDataCategory
                category={aoCategory}
                enableArticles
                enableOverviewLink
                enableRowLimit
                items={
                    _.orderBy<CategoryHierarchyDTO>(
                        aoCategory.children,
                        ["displayOrder", "categoryName"],
                        ["asc", "asc"]
                    )
                }
                parentCategories={[]}
            />
        );
    } // onPanelMainDataCategoriesRenderCategory

    render() {
        return (
            <div className="page-home">
                <Heading
                    className="page-home-heading"
                    description={I18n.t("frontend_page_home_heading_description")}
                    title={I18n.t("frontend_text_NationalSocialCareDataPortalForWales")}
                />

                <PanelInfographics groupId="page-home"/>

                <PanelSearch />

                <div className="page-home-section">
                    <div className="panel-container-updates-frequently-visited">
                        <div className="panel-container-updates">
                            <PanelUpdates />
                        </div>

                        <div className="panel-container-frequently-visited">
                            <PanelFrequentlyVisited />
                        </div>
                    </div>
                    
                    <PanelMainDataCategories
                        addArticlesCategory
                        onRenderCategory={this.onPanelMainDataCategoriesRenderCategory}
                        title={I18n.t("frontend_categories_panel_title")} />

                </div>
            </div>
        );
    }
}

// Link with Redux store

const mapStateToProps =
    (state: any) => ({
        locale: state.i18n.locale
    });

export default connect(mapStateToProps)(PageHome);