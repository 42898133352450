import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import * as _ from "lodash";
import { ArticleDTO } from "./models/ArticleDTO";
import { CategoryHierarchyDTO } from "./models/CategoryHierarchyDTO";
import { DatasetDTO } from "./models/DatasetDTO";
import { DatasetIndicatorGroupDTO } from "./models/DatasetIndicatorGroupDTO";
import { GeographyDTO } from "./models/GeographyDTO";
import { GeographyTypeDTO } from "./models/GeographyTypeDTO";
import { IndicatorDTO } from "./models/IndicatorDTO";
import { IndicatorTypeDTO } from "./models/IndicatorTypeDTO";
import { LanguageDTO } from "./models/LanguageDTO";
import { LanguageMetadataDTO } from "./models/LanguageMetadataDTO";
import { UIInfographicConfigurationDTO } from "./models/UIInfographicConfigurationDTO";
import { UpdateDTO } from "./models/UpdateDTO";


export const scwApi =
    createApi(
        {
            reducerPath: 'scw_api',
            baseQuery:
                fetchBaseQuery(
                    {
                        baseUrl: process.env.REACT_APP_API_URL
                    }
                ),
            endpoints:
                (builder) => (
                    {
                        article:
                            builder.query<ArticleDTO, { languageCode: string; id: string; }>({
                                query: ({ languageCode, id }) => `Articles/Get/${languageCode}/${id}`,
                                keepUnusedDataFor: 60,
                            }),
                        articlesList:
                            builder.query<ArticleDTO, { languageCode: string }>({
                                query: ({ languageCode }) => `Articles/GetList/${languageCode}`,
                                keepUnusedDataFor: 60,
                            }),
                        categoriesGetHierarchyAll:
                            builder.query<CategoryHierarchyDTO[], string>({
                                query: (languageCode) => `Categories/GetHierarchyAll/${languageCode}`,
                                keepUnusedDataFor: 60, 
                            }),
                        categoriesGetOfDataset:
                            builder.query<CategoryHierarchyDTO[], { languageCode: string; datasetId: number; }>({
                                query: ({ languageCode, datasetId }) => `Categories/GetOfDataset/${languageCode}/${datasetId}`,
                                keepUnusedDataFor: 60,
                            }),
                        categoriesGetOfIndicator:
                            builder.query<CategoryHierarchyDTO[], { languageCode: string; indicatorId: number; }>({
                                query: ({ languageCode, indicatorId }) => `Categories/GetOfIndicator/${languageCode}/${indicatorId}`,
                                keepUnusedDataFor: 60,
                            }),
                        configurationGetInfographics:
                            builder.query<UIInfographicConfigurationDTO[], string>({
                                query: (groupId) => `Configuration/GetInfographics/${groupId}`,
                                keepUnusedDataFor: 60,
                            }),
                        datasetsGet:
                            builder.query<DatasetDTO[], { languageCode: string; id: number }>({
                                query: ({ languageCode, id }) => `Datasets/Get/${languageCode}/${id}`,
                                keepUnusedDataFor: 60,
                            }),
                        datasetsGetOfCategory:
                            builder.query<DatasetDTO[], { languageCode: string; categoryId: number }>({
                                query: ({ languageCode, categoryId }) => `Datasets/GetOfCategory/${languageCode}/${categoryId}`,
                                keepUnusedDataFor: 60,
                            }),
                        geographiesGet:
                            builder.query <GeographyDTO[], { languageCode: string; typeId: number; }>({
                                query: ({languageCode, typeId}) => `Geographies/Get/${languageCode}/${typeId}`,
                                keepUnusedDataFor: 60,
                            }),
                        geographiesGetTypes:
                            builder.query<GeographyTypeDTO[], string>({
                                query: (languageCode) => `Geographies/GetTypes/${languageCode}`,
                                keepUnusedDataFor: 60,
                            }),
                        indicatorsGet:
                            builder.query<IndicatorDTO[], { languageCode: string; id: number }>({
                                query: ({ languageCode, id }) => `Indicators/Get/${languageCode}/${id}`,
                                keepUnusedDataFor: 60,
                            }),
                        indicatorsGetFrequentlyVisited:
                            builder.query<IndicatorDTO[], string>({
                                query: (languageCode) => `Indicators/GetFrequentlyVisited/${languageCode}`,
                                keepUnusedDataFor: 5,
                            }),
                        indicatorsGetOfCategory:
                            builder.query<IndicatorDTO[], { languageCode: string; categoryId: number; datasetId?: number }>({
                                query: ({ languageCode, categoryId, datasetId }) =>
                                    (datasetId) ? `Indicators/GetOfCategory/${languageCode}/${categoryId}/${datasetId}` : `Indicators/GetOfCategory/${languageCode}/${categoryId}`,
                                keepUnusedDataFor: 60,
                            }),
                        indicatorsGetOfCategoryBranch:
                            builder.query<IndicatorDTO[], { languageCode: string; categoryId: number }>({
                                query: ({ languageCode, categoryId }) => `Indicators/GetOfCategoryBranch/${languageCode}/${categoryId}`,
                                keepUnusedDataFor: 60,
                            }),
                        indicatorsGetOfDataset:
                            builder.query<DatasetIndicatorGroupDTO[], { languageCode: string; datasetId: number }>({
                                query: ({ languageCode, datasetId }) => `Indicators/GetOfDataset/${languageCode}/${datasetId}`,
                                keepUnusedDataFor: 60,
                            }),
                        indicatorsGetMetadata:
                            builder.query<LanguageMetadataDTO[], { languageCode: string; id: number }>({
                                query: ({ languageCode, id }) => `Indicators/GetMetadata/${languageCode}/${id}`,
                                keepUnusedDataFor: 60,
                            }),
                        indicatorsGetTypes:
                            builder.query<IndicatorTypeDTO[], string>({
                                query: (languageCode) => `Indicators/GetTypes/${languageCode}`,
                                keepUnusedDataFor: 60,
                            }),
                        indicatorsSearch:
                            builder.query<IndicatorDTO[], { languageCode: string; searchText: string }>({
                                query: (obj) => {
                                    const { languageCode, searchText } = obj;
                                    return {
                                        url: `Indicators/Search/${languageCode}/${searchText}`
                                    }
                                },
                                keepUnusedDataFor: 5,
                            }),
                        languageGet:
                            builder.query<LanguageDTO[], string>({
                                query: (languageCode) => `Language/Get/${languageCode}`,
                                keepUnusedDataFor: 60,
                            }),
                        updatesGet:
                            builder.query<UpdateDTO[], string>({
                                query: (languageCode) => `Updates/Get/${languageCode}`,
                                transformResponse: (baseQueryReturnValue: any, meta: any, arg: any) => {
                                    return _.map(
                                        baseQueryReturnValue as any[],
                                        update => {
                                            return {
                                                description: update.description as string,
                                                indicatorID: update.indicatorID as number,
                                                indicatorName: update.indicatorName as string,
                                                indicatorTypeName: update.indicatorTypeName as string,
                                                timestamp: new Date(update.timestamp as string),
                                                updateID: update.updateID as number,
                                            };
                                        }
                                    );
                                },
                                keepUnusedDataFor: 1,
                            }),
                    }
                ),
        },
    );


export const scwClientAppApi =
    createApi(
        {
            reducerPath: 'scw_client_app_api',
            baseQuery:
                fetchBaseQuery(
                    {
                        baseUrl: "/"
                    }
                ),
            endpoints:
                (builder) => (
                    {
                        embedInfoGetEmbedInfo:
                            builder.query<any, string>({
                                query: (reportIdName) => `EmbedInfo/GetEmbedInfo${reportIdName ? `/${reportIdName}` : ""}`,
                                keepUnusedDataFor: 60,
                            }),
                    }
                ),
        },
    );