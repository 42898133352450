import * as React from "react";
import { connect } from "react-redux";
import { I18n, setLocale } from "react-redux-i18n";
import { LanguageService } from "../services/LanguageService";
import ButtonHeaderBarTop from "./ButtonHeaderBarTop";
import "./HeaderBarTop.css";


type HeaderBarTopProps = {
    locale: string[2],
    setLocale: Function
};

class HeaderBarTop extends React.PureComponent<HeaderBarTopProps, any> {

    constructor(props: any) {
        super(props);
        this.state = { textSizeState: "frontend_button_IncreaseTextSize" };

    };

    private onSetLocale = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, locale: string) => {
        e.preventDefault();

        this.props.setLocale(locale);
        localStorage.setItem("language", locale);
    } // onSetLocale

    private onChangeTextSize = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (document.body.classList.contains("zoom")) {
            document.body.classList.remove("zoom");
            this.setState({ textSizeState: "frontend_button_IncreaseTextSize" });
        }
        else {
            document.body.classList.add("zoom");
            this.setState({ textSizeState: "frontend_button_DecreaseTextSize" })
        }

    } // onChangeTextSize

    private onRedirectToAccessibility = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        const loWindow = window.open(I18n.t("frontend_button_accessibility_url"), "_blank");
        if (loWindow)
            loWindow.focus();
    } // onRedirectToAccessibility

    render() {
        const lbIsZoomSet = document.body.classList.contains("zoom");

        return (
            <div className="header-top-bar">
                <div className="group">
                    <ButtonHeaderBarTop label={I18n.t("frontend_button_Accessibility")} scwIconName="scw-icon-accessibility" onClick={(e) => this.onRedirectToAccessibility(e)} />
                    <ButtonHeaderBarTop label={I18n.t(this.state.textSizeState)} scwIconName={lbIsZoomSet ? "scw-icon-zoom-unset" : "scw-icon-zoom-set"} onClick={(e) => this.onChangeTextSize(e)} />
                </div>
                <div className="group">
                    <ButtonHeaderBarTop label="Cymraeg" active={(this.props.locale === LanguageService.CYMRAEG)} onClick={(e) => this.onSetLocale(e, LanguageService.CYMRAEG)} />
                    <ButtonHeaderBarTop label="English" active={(this.props.locale === LanguageService.ENGLISH)} onClick={(e) => this.onSetLocale(e, LanguageService.ENGLISH)} />
                </div>
            </div>
        );
    } // render
};

// Link with Redux store

const mapStateToProps = (state: any) => ({ locale: state.i18n.locale });
const mapDispatchToProps = (dispatch: Function) => {
    return {
        setLocale: (locale: string) => dispatch(setLocale(locale))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBarTop);