import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import * as _ from "lodash";
import { scwApi } from "../../services/Api";
import PanelDataCategory from "../panel/PanelDataCategory";
import PanelFrequentlyVisited from "../panel/PanelFrequentlyVisited";
import PanelMainDataCategories from "../panel/PanelMainDataCategories";
import PanelUpdates from "../panel/PanelUpdates";
import Utils from "../../services/Utils";
import { CategoryHierarchyDTO } from "../../services/models/CategoryHierarchyDTO";
import "./PageArticles.css";
import { ArticleDTO } from "../../services/models/ArticleDTO";


type PageArticlesProps = RouteComponentProps<{}> & {
    dataArticlesListGet: any,
    dataCategoriesHierarchy: any,
    locale: string[2],
    scwApiArticlesListGet: Function,
}

type PageArticlesState = {
    articlesList: ArticleDTO[] | null,
    categoriesMajor: CategoryHierarchyDTO[],
}

class PageArticles extends React.Component<PageArticlesProps, PageArticlesState> {

    constructor(props: Readonly<PageArticlesProps>) {
        super(props);

        this.state = {
            articlesList: null,
            categoriesMajor: [],
        }
    } // constructor

    static getDerivedStateFromProps(nextProps: Readonly<PageArticlesProps>,
        prevState: PageArticlesState): Partial<PageArticlesState> | null {
        if (!nextProps.dataCategoriesHierarchy.isSuccess)
            return null;

        const nextStateCategoriesMajor =
            _.orderBy(
                nextProps.dataCategoriesHierarchy.data,
                ["displayOrder", "categoryName"],
                ["asc", "asc"]
            );

        let nextStateArticlesList = null;
        const loArticlesListResponse = nextProps.dataArticlesListGet(nextProps.locale);
        if (loArticlesListResponse.isSuccess) {
            nextStateArticlesList = JSON.parse(loArticlesListResponse.data);
        } else if (!loArticlesListResponse.isLoading && !loArticlesListResponse.isError) {
            nextProps.scwApiArticlesListGet(nextProps.locale);
        }

        return {
            articlesList: nextStateArticlesList,
            categoriesMajor: nextStateCategoriesMajor,
        };
    } // getDerivedStateFromProps

    navigate = (asUrl: string) => {
        this.props.history.push(asUrl);
    } // navigate

    onPanelDataCategoryGoToFullList = (asCategoryId: string | number) => {
        this.navigate(`/articles-list?c=${asCategoryId}`);
    } // onPanelDataCategoryGoToFullList

    onPanelDataCategoryRenderItem = (aoItem: ArticleDTO) => {
        return (
            <div key={aoItem.id} className="category-subcategory">
                <Link to={{ pathname: `/article`, search: `a=${aoItem.id}` }}>
                    <span className="subcategory-name">
                        {aoItem.title}
                    </span>
                </Link>

                <span className="subcategory-chevron chevron right">
                </span>
            </div>
        );
    } // onPanelDataCategoryRenderItem

    onPanelMainDataCategoriesRenderCategory = (aoCategory: CategoryHierarchyDTO): JSX.Element | null => {
        if (!this.state.articlesList)
            return null;

        const loCategoryArticlesList = this.state.articlesList.filter(aoArticle => Utils.doesArticleBelongToCategory(aoArticle, aoCategory.categoryID));

        if (loCategoryArticlesList.length === 0)
            return null;

        return (
            <PanelDataCategory
                category={aoCategory}
                enableOverviewLink={false}
                enableGoToFullList={true}
                onGoToFullList={() => this.onPanelDataCategoryGoToFullList(aoCategory.categoryID)}
                onRenderItem={this.onPanelDataCategoryRenderItem}
                items={
                    _.orderBy<ArticleDTO>(
                        loCategoryArticlesList,
                        ["title"],
                        ["asc"]
                    )
                }
                parentCategories={[]}
            />
        );
    } // onPanelMainDataCategoriesRenderCategory

    render() {
        return (
            <div className="page-articles">
                <div className="page-articles-section">
                    <Breadcrumb listTag="div">
                        <BreadcrumbItem tag="a" onClick={() => this.navigate("/")}>{I18n.t("frontend_menuItem_Home")}</BreadcrumbItem>
                        <BreadcrumbItem active tag="span">{I18n.t("frontend_text_Articles")}</BreadcrumbItem>
                    </Breadcrumb>

                    {
/*
                    <div className="panel-container-updates-frequently-visited">
                        <div className="panel-container-updates">
                            <PanelUpdates />
                        </div>

                        <div className="panel-container-frequently-visited">
                            <PanelFrequentlyVisited />
                        </div>
                    </div>
*/
                    }

                    <PanelMainDataCategories
                        onRenderCategory={this.onPanelMainDataCategoriesRenderCategory}
                        title={I18n.t("frontend_text_Articles")} />
                </div>
            </div>
        );
    } // render
}


// Link with Redux store

const mapStateToProps =
(state: any) => (
    {
        locale: state.i18n.locale,        
        dataArticlesListGet: (languageCode: string) => scwApi.endpoints.articlesList.select({ "languageCode": languageCode })(state),
        dataCategoriesHierarchy: scwApi.endpoints.categoriesGetHierarchyAll.select(state.i18n.locale)(state),
    }
);

const mapDispatchToProps =
(dispatch: Function) => {
    return {
        scwApiArticlesListGet: (languageCode: string, categoryIds: string[]) => dispatch(scwApi.endpoints.articlesList.initiate({ "languageCode": languageCode })),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageArticles));