import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import * as _ from "lodash";
import { scwApi } from "../../services/Api";
import { Link } from "react-router-dom";
import { UpdateDTO } from "../../services/models/UpdateDTO";
import LinkSee from "../LinkSee";
import Throbber from "../Throbber";
import "./PanelUpdates.css";


type PanelUpdatesState = {
    data: UpdateDTO[] | null,
}

type PanelUpdatesProps = {
    locale: string[2],
    dataUpdates: any,
    scwApiUpdatesGet: Function,
};

class PanelUpdates extends React.Component<PanelUpdatesProps, PanelUpdatesState> {

    private static fiDisplayRowsLimit = 2;

    constructor(props: Readonly<PanelUpdatesProps>) {
        super(props);

        this.state = {
            data: null,
        };

        props.scwApiUpdatesGet(this.props.locale);
    } // constructor

    static getDerivedStateFromProps(nextProps: Readonly<PanelUpdatesProps>, prevState: PanelUpdatesState):
        Partial<PanelUpdatesState> | null {
        return {
            data: (
                nextProps.dataUpdates.isSuccess
                    ? _.take(
                        _.orderBy(
                            nextProps.dataUpdates.data,
                            "timestamp",
                            "desc"
                        ),
                        PanelUpdates.fiDisplayRowsLimit
                    )
                    : null
            ),
        }
    } // getDerivedStateFromProps

    render() {
        if (!this.state.data)
            return <Throbber />;

        return (
            <div className="panel-updates">
                <div className="panel-title">
                    {I18n.t("frontend_updates_panel_title")}
                </div>
                {
                    (this.state.data) && (
                        (this.state.data as UpdateDTO[]).map(
                            update => {
                                return (
                                    <div className="update" key={update.updateID}>
                                        <div className="update-type-and-timestamp-container">
                                            <span className="update-type-shape"></span>
                                            <span className="update-type-name">{update.indicatorTypeName}</span>
                                            <span className="update-timestamp">{update.timestamp.toLocaleDateString(this.props.locale + "-GB")}</span>
                                        </div>
                                        <div className="update-indicator-container">
                                            <Link to={{ pathname: `/dataset`, search: `i=${(update.indicatorID ? update.indicatorID : "")}` }}>
                                                <span className="update-indicator-name">

                                                    {update.indicatorName}
                                                </span>
                                                <span className="update-indicator-chevron chevron right">
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                );
                            }
                        )
                    )
                }
                {/*<LinkSee mode="all" />*/}
            </div>
        );
    } // render

}

// Link with Redux store

const mapStateToProps =
    (state: any) => (
        {
            locale: state.i18n.locale,
            dataUpdates: scwApi.endpoints.updatesGet.select(state.i18n.locale)(state),
        }
    );

const mapDispatchToProps =
    (dispatch: Function) => {
        return {
            scwApiUpdatesGet: (languageCode: string) => dispatch(scwApi.endpoints.updatesGet.initiate(languageCode)),
        }
    };

export default connect(mapStateToProps, mapDispatchToProps)(PanelUpdates);