import * as React from "react";
import { connect } from "react-redux";
import * as _ from "lodash";
import { scwApi, scwClientAppApi } from "../../services/Api";
import { PowerBIEmbed } from "powerbi-client-react";
import { models, Report } from "powerbi-client";
import "./PanelInfographic.css";


type PanelInfographicProps = {
    dataPowerBiEmbedInfo: any,
    indicatorId: number,
    locale: string[2],
    scwClientAppApiEmbedInfoGetEmbedInfo: Function,
}

type PanelInfographicState = {
    dataPowerBiEmbedInfo: any,
    isDataPowerBiEmbedInfoLoaded: boolean,
}

class PanelInfographic extends React.Component<PanelInfographicProps, PanelInfographicState> {

    report: Report | null = null;

    constructor(props: PanelInfographicProps) {
        super(props);

        this.state = {
            dataPowerBiEmbedInfo: null,
            isDataPowerBiEmbedInfoLoaded: false,
        }
    } // constructor

    static getDerivedStateFromProps(nextProps: Readonly<PanelInfographicProps>,
        prevState: PanelInfographicState): Partial<PanelInfographicState> | null {
        const loScwClientAppApiDataPowerBiEmbedInfo = nextProps.dataPowerBiEmbedInfo("Green Box");

        if (
            !loScwClientAppApiDataPowerBiEmbedInfo.isSuccess
                && !loScwClientAppApiDataPowerBiEmbedInfo.isLoading
        )
            nextProps.scwClientAppApiEmbedInfoGetEmbedInfo("Green Box");

        return {
            dataPowerBiEmbedInfo: loScwClientAppApiDataPowerBiEmbedInfo.data,
            isDataPowerBiEmbedInfoLoaded: loScwClientAppApiDataPowerBiEmbedInfo.isSuccess,
        };
    } // getDerivedStateFromProps
    
    render() {
        return (
            <div className="green-type-1">
                {
                    (
                        this.state.isDataPowerBiEmbedInfoLoaded
                        && !_.isNil(this.state.dataPowerBiEmbedInfo)
                        && (
                            <PowerBIEmbed
                                cssClassName={"powerbi-embed-container"}

                                embedConfig={{
                                    type: "report",   // Supported types: report, dashboard, tile, visual and qna
                                    id: this.state.dataPowerBiEmbedInfo.EmbedReport[0].ReportId,
                                    embedUrl: this.state.dataPowerBiEmbedInfo.EmbedReport[0].EmbedUrl,
                                    accessToken: this.state.dataPowerBiEmbedInfo.EmbedToken.Token,
                                    tokenType: models.TokenType.Embed,
                                    filters: [
                                        {
                                            $schema: "http://powerbi.com/product/schema#basic",
                                            target: {
                                                table: "Indicators",
                                                column: "IndicatorID"
                                            },
                                            operator: "In",
                                            values: [this.props.indicatorId],
                                            filterType: 1,
                                            requireSingleSelection: false,
                                            displaySettings: {
                                                isLockedInViewMode: true,
                                                isHiddenInViewMode: true, 
                                            }
                                        },
                                        {
                                            $schema: "http://powerbi.com/product/schema#basic",
                                            target: {
                                                table: "Geographies",
                                                column: "Languages.Code.GeoEntityName"
                                            },
                                            operator: "In",
                                            values: [this.props.locale],
                                            filterType: 1,
                                            requireSingleSelection: false,
                                            displaySettings: {
                                                isLockedInViewMode: true,
                                                isHiddenInViewMode: true,
                                            }
                                        },
                                        {
                                            $schema: "http://powerbi.com/product/schema#basic",
                                            target: {
                                                table: "Indicators",
                                                column: "Languages.Code.Name"
                                            },
                                            operator: "In",
                                            values: [this.props.locale],
                                            filterType: 1,
                                            requireSingleSelection: false,
                                            displaySettings: {
                                                isLockedInViewMode: true,
                                                isHiddenInViewMode: true,
                                            }
                                        }
                                    ],
                                    settings: {
                                        filterPaneEnabled: false,
                                        navContentPaneEnabled: false,
                                        panes: {
                                            filters: {
                                                expanded: false,
                                                visible: false
                                            }
                                        },
                                        background: models.BackgroundType.Transparent,
                                    }
                                }}

                                getEmbeddedComponent={
                                    (embeddedReport: any) => {
                                        this.report = embeddedReport as Report;

                                        this.report.on("loaded", function() {
                                            // console.log('Report loaded')
                                        });
                                        this.report.on("rendered", function() {
                                            // console.log('Report rendered');
                                        });
                                        this.report.on("loaded", function(event) {
                                            // console.log(event.detail);
                                        });
                                    }
                                }
                            />
                            )   
                    )
                }
            </div>
        );
    } // render
}

// Link with Redux store

const mapStateToProps =
    (state: any) => (
        {
            locale: state.i18n.locale,
            dataPowerBiEmbedInfo: (reportIdName: string) => scwClientAppApi.endpoints.embedInfoGetEmbedInfo.select(reportIdName)(state),
        }
    );

const mapDispatchToProps =
(dispatch: Function) => {
        return {
            scwClientAppApiEmbedInfoGetEmbedInfo: (reportIdName: string) => dispatch(scwClientAppApi.endpoints.embedInfoGetEmbedInfo.initiate(reportIdName)),
        }
    };

export default connect(mapStateToProps, mapDispatchToProps)(PanelInfographic);