import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import Heading from "../Heading";

import "./PageAbout.css";


const PageAbout = () => (
    <div className="page-about">
        <Heading
            className="page-about-heading"
            description={I18n.t("frontend_page_about_heading_description")}
            title={I18n.t("frontend_page_about_heading_title")}
        />

        <div
            className="page-about-section"
            dangerouslySetInnerHTML={{ __html: I18n.t("frontend_page_about_section_html") }}>
        </div>
    </div>
);

const mapStateToProps = (state: any) => ({ locale: state.i18n.locale });

export default connect(mapStateToProps)(PageAbout);
