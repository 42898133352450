import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import * as _ from "lodash";
import { scwApi } from "../../services/Api";
import Utils from "../../services/Utils";
import "./PageArticlesList.css";
import { ArticleDTO } from "../../services/models/ArticleDTO";


type PageArticlesListProps = RouteComponentProps<{}> & {
    dataArticlesListGet: any,
    locale: string[2],
    scwApiArticlesListGet: Function,
}

type PageArticlesListOrderByState = {
    fields: string[],
    directions: (boolean | "asc" | "desc")[],
}

type PageArticlesListState = {
    articlesList: ArticleDTO[],
    orderBy: PageArticlesListOrderByState,
}

class PageArticlesList extends React.Component<PageArticlesListProps, PageArticlesListState> {

    constructor(props: Readonly<PageArticlesListProps>) {
        super(props);

        this.state = {
            articlesList: [],
            orderBy: {
                fields: ["postDate", "title", "author.firstName", "author.lastName"],
                directions: ["asc", "asc", "asc", "asc"],
            },
        }
    } // constructor

    static getDerivedStateFromProps(nextProps: Readonly<PageArticlesListProps>,
        prevState: PageArticlesListState): Partial<PageArticlesListState> | null {

        const loParams = new URLSearchParams(nextProps.location.search);

        const lsCategoryId = loParams.get("c");        

        let nextStateArticlesList: ArticleDTO[] = [];
        if (lsCategoryId) {
            const loArticlesListResponse = nextProps.dataArticlesListGet(nextProps.locale);
            if (loArticlesListResponse.isSuccess) {
                nextStateArticlesList = JSON.parse(loArticlesListResponse.data);
            } else if (!loArticlesListResponse.isLoading && !loArticlesListResponse.isError) {
                nextProps.scwApiArticlesListGet(nextProps.locale);
            }

            nextStateArticlesList =
                nextStateArticlesList.filter(aoArticle => Utils.doesArticleBelongToCategory(aoArticle, lsCategoryId));
        }

        return {
            articlesList:
                _.orderBy<ArticleDTO>(
                    nextStateArticlesList,
                    prevState.orderBy.fields,
                    prevState.orderBy.directions,
                ),
        };
    } // getDerivedStateFromProps

    navigate = (asUrl: string) => {
        this.props.history.push(asUrl);
    } // navigate

    onArticleClick = (aoArticle: ArticleDTO) => {
        this.navigate(`/article?a=${aoArticle.id}`);
    } // onArticleClick

    onHeaderClick = (column: string) => {
        switch (column) {
            case "title":
                this.setState(
                    prevState => {
                        return {
                            ...prevState,
                            orderBy: this.toggleOrderBy(prevState.orderBy, "title"),
                        }
                    }
                );

                break;

            case "publisher":
                this.setState(
                    prevState => {
                        return {
                            ...prevState,
                            orderBy: this.toggleOrderBy(this.toggleOrderBy(prevState.orderBy, "author.lastName"), "author.firstName"),
                        }
                    }
                );

                break;

            case "date":
                this.setState(
                    prevState => {
                        return {
                            ...prevState,
                            orderBy: this.toggleOrderBy(prevState.orderBy, "postDate"),
                        }
                    }
                );

                break;
            default:
        }
    } // onHeaderClick

    renderOrderByChevron = (column: string): JSX.Element | null => {
        let idx;
        switch (column) {
            case "title":
                idx = _.findIndex(this.state.orderBy.fields, aoField => aoField == "title");
                break;

            case "publisher":
                idx = _.findIndex(this.state.orderBy.fields, aoField => aoField == "author.firstName");
                break;

            case "date":
                idx = _.findIndex(this.state.orderBy.fields, aoField => aoField == "postDate");
                break;

            default:
                idx = -1;
        }

        if (idx < 0)
            return null;

        if (this.state.orderBy.directions[idx] === "asc")
            return (
                <span className="order-by-chevron chevron up" />
            );
        else if (this.state.orderBy.directions[idx] === "desc")
            return (
                <span className="order-by-chevron chevron bottom" />
        );
        else
            return null;
    } // renderOrderByChevron

    toggleOrderBy = (orderBy: PageArticlesListOrderByState, fieldName: string): PageArticlesListOrderByState => {
        const idx = _.findIndex(orderBy.fields, aoField => aoField == fieldName);
        if (idx < 0)
            return orderBy;

        const direction = orderBy.directions[idx];
        const newDirection = direction === "asc" ? "desc" : "asc";

        const newFields = orderBy.fields.concat();
        newFields.splice(idx, 1);        

        const newDirections = orderBy.directions.concat();
        newDirections.splice(idx, 1);
        
        return {
            fields: [fieldName].concat(newFields),
            directions: ([newDirection] as (boolean | "asc" | "desc")[]).concat(newDirections),
        };
    } // toggleOrderBy

    render() {
        return (
            <div className="page-articles-list">
                <div className="page-articles-list-section">
                    <Breadcrumb listTag="div">
                        <BreadcrumbItem tag="a" onClick={() => this.navigate("/")}>{I18n.t("frontend_menuItem_Home")}</BreadcrumbItem>
                        <BreadcrumbItem active tag="span">{I18n.t("frontend_text_Articles")}</BreadcrumbItem>
                    </Breadcrumb>

                    <table className="page-articles-list-table">
                        <thead>
                            <tr>
                                <th onClick={() => this.onHeaderClick("title")}>
                                    Title
                                    {
                                        this.renderOrderByChevron("title")
                                    }
                                </th>
                                <th onClick={() => this.onHeaderClick("publisher")}>
                                    Publisher
                                    {
                                        this.renderOrderByChevron("publisher")
                                    }
                                </th>
                                <th onClick={() => this.onHeaderClick("date")}>
                                    Date
                                    {
                                        this.renderOrderByChevron("date")
                                    }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (this.state.articlesList ?? []).map(
                                    aoArticle => (
                                        <tr className="page-articles-list-table-row" onClick={() => this.onArticleClick(aoArticle)} key={aoArticle.id}>
                                            <td style={{ width: '60%' }}>{aoArticle.title}</td>
                                            <td style={{ width: '30%' }}>{aoArticle.author.firstName} {aoArticle.author.lastName}</td>
                                            <td style={{ width: '10%' }}>{(new Date(aoArticle.postDate)).toLocaleDateString(this.props.locale)}</td>
                                        </tr>
                                    )
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    } // render
}


// Link with Redux store

const mapStateToProps =
(state: any) => (
    {
        locale: state.i18n.locale,        
        dataArticlesListGet: (languageCode: string) => scwApi.endpoints.articlesList.select({ "languageCode": languageCode })(state),
        dataCategoriesHierarchy: scwApi.endpoints.categoriesGetHierarchyAll.select(state.i18n.locale)(state),
    }
);

const mapDispatchToProps =
(dispatch: Function) => {
    return {
        scwApiArticlesListGet: (languageCode: string, categoryIds: string[]) => dispatch(scwApi.endpoints.articlesList.initiate({ "languageCode": languageCode })),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageArticlesList));