import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Table } from "reactstrap";
// @ts-ignore
import WGLogoPNG from "../assets/img/wg_logo.png";
import "./Footer.css";


type FooterProps = {
    locale: string[2]
};

class Footer extends React.PureComponent<FooterProps, any> {
    public render() {

        return (
            <footer className="footer">
                <div className="row u-padding">
                    <div className="table">
                        <Table borderless>
                            <tbody>
                                <tr>
                                    <td>
                                        <ul className="link-list">
                                            <li className="footer__nav-item"><a href={I18n.t("frontend_footer_link_news_events_and_consultations")} target="_blank">{I18n.t("frontend_footer_text_news_events_and_consultations")}</a></li>
                                            <li className="footer__nav-item"><a href={I18n.t("frontend_footer_link_the_accolades")} target="_blank">{I18n.t("frontend_footer_text_the_accolades")}</a></li>
                                            <li className="footer__nav-item"><a href={I18n.t("frontend_footer_link_contact_us")} target="_blank">{I18n.t("frontend_footer_text_contact_us")}</a></li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul className="link-list">
                                            <li className="footer__nav-item"><a href={I18n.t("frontend_footer_link_site_map")} target="_blank">{I18n.t("frontend_footer_text_site_map")}</a></li>
                                            <li className="footer__nav-item"><a href={I18n.t("frontend_footer_link_privacy_notice")} target="_blank">{I18n.t("frontend_footer_text_privacy_notice")}</a></li>
                                            <li className="footer__nav-item"><a href={I18n.t("frontend_footer_link_accessibility_statement")} target="_blank">{I18n.t("frontend_footer_text_accessibility_statement")}</a></li>
                                        </ul>
                                    </td>
                                    <td>
                                        <img src={WGLogoPNG} className="logo" alt="Lywodraeth Cymru | Welsh Government" />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="details">
                        <div className="contact">
                            <a href="mailTo:data@socialcare.wales" className="email">{I18n.t("frontend_footer_email")}</a>
                            <span className="copyright">{I18n.t("frontend_footer_copyright")}</span>
                        </div>

                        <div className="social-channels">
                            <a href={I18n.t("frontend_footer_link_facebook")} target="_blank" rel="noopener" className="footer__social-icon footer__social-icon--facebook" aria-label="Facebook">
                                <span className="scw-icon scw-icon-social-facebook"></span>
                            </a>

                            <a href={I18n.t("frontend_footer_link_twitter")} target="_blank" rel="noopener" className="footer__social-icon footer__social-icon--twitter" aria-label="Twitter">
                                <span className="scw-icon scw-icon-social-twitter"></span>
                            </a>

                            <a href={I18n.t("frontend_footer_link_youtube")} target="_blank" rel="noopener" className="footer__social-icon footer__social-icon--youtube" aria-label="YouTube">
                                <span className="scw-icon scw-icon-social-youtube"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
};

const mapStateToProps = (state: any) => ({ locale: state.i18n.locale });

export default connect(mapStateToProps)(Footer);