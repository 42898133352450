import * as React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import * as _ from "lodash";
import { I18n } from "react-redux-i18n";
import { DatasetDTO } from "../../services/models/DatasetDTO";
import { DatasetIndicatorGroupDTO } from "../../services/models/DatasetIndicatorGroupDTO";
import { IndicatorDTO } from "../../services/models/IndicatorDTO";

import "./PanelSelectIndicator.css";

type ItemDTO = DatasetDTO | DatasetIndicatorGroupDTO | IndicatorDTO;

type PanelSelectIndicatorProps = {   
    datasets?: DatasetDTO[],
    indicators?: IndicatorDTO[],
    indicatorsOfDataset?: DatasetIndicatorGroupDTO[],
    multipleSelection?: boolean,
    onSelectedItems?: any,
    selectedItems?: ItemDTO[],
}

type PanelSelectIndicatorState = {
    items?: ItemDTO[],
    selectedItems?: ItemDTO[],
}

class PanelSelectIndicator extends React.Component<PanelSelectIndicatorProps, PanelSelectIndicatorState> {

    constructor(props: Readonly<PanelSelectIndicatorProps>) {
        super(props);

        this.state = {};
    } // constructor

    static getDerivedStateFromProps(nextProps: Readonly<PanelSelectIndicatorProps>, prevState: PanelSelectIndicatorState):
        Partial<PanelSelectIndicatorState> | null {
        return {
            items:
                !_.isNil(nextProps.indicators) || !_.isNil(nextProps.datasets) || !_.isNil(nextProps.indicatorsOfDataset) ?
                        _.sortBy(
                            (nextProps.datasets ?? []) as ItemDTO[],
                            [(item) => { return ('datasetIndicatorGroupID' in item) ? item.datasetIndicatorGroupName : item.name; }]
                        ).concat(
                            (
                                _.sortBy(nextProps.indicators ?? [], ['displayOrder', 'name']) as ItemDTO[]
                            ).concat(
                                _.sortBy(nextProps.indicatorsOfDataset ?? [], ['displayOrder', 'datasetIndicatorGroupName']) as ItemDTO[]
                            )
                        ) : undefined,
            selectedItems: prevState.selectedItems ?? nextProps.selectedItems, // Set selected items from props only when component has nothing set internally
        };
    }

    executeOnSelectedItemsCallback() {
        if (!this.props.onSelectedItems)
            return;

        this.props.onSelectedItems(this.state.selectedItems);
    } // executeOnSelectedItemsCallback

    getName(aoItem: ItemDTO): string {
        if ('datasetIndicatorGroupID' in aoItem)
            return aoItem.datasetIndicatorGroupName;
        else
            return aoItem.name;
    } // getName

    getTypeName(aoItem: ItemDTO): string {
        if ('typeName' in aoItem)
            return aoItem.typeName;
        else if ('datasetID' in aoItem)
            return I18n.t("frontend_text_dataset");
        else if (('datasetIndicatorGroupID' in aoItem) && (aoItem.indicators.length > 0))
            return aoItem.indicators[0].typeName; // It is assumed that indicators in the group are of the same type, just differ in unit
        else
            throw new Error("Unknown type");
    } // getTypeName

    findSelectedItemIdx(aoItem: ItemDTO, aoState: PanelSelectIndicatorState | undefined = undefined): number {
        const lookMeasure = 'indicatorID' in aoItem;
        const lookDataset = 'datasetID' in aoItem;
        const lookDatasetIndicatorGroup = 'datasetIndicatorGroupID' in aoItem;
        const state = aoState ?? this.state;

        return (
            _.isNil(state.selectedItems)
                ? -1
                : _.findIndex(
                    state.selectedItems,
                    aoSelectedItem =>
                        (lookMeasure && 'indicatorID' in aoSelectedItem && (aoItem as IndicatorDTO).indicatorID === aoSelectedItem.indicatorID)
                        || (lookDataset && 'datasetID' in aoSelectedItem && (aoItem as DatasetDTO).datasetID === aoSelectedItem.datasetID)
                        || (lookDatasetIndicatorGroup && 'datasetIndicatorGroupID' in aoSelectedItem && (aoItem as DatasetIndicatorGroupDTO).datasetIndicatorGroupID === aoSelectedItem.datasetIndicatorGroupID)
                  )
        );
    } // findSelectedItemIdx

    onClickItem(e: any, aoItem: ItemDTO) {
        if (this.props.multipleSelection) {
            const selectedItemIdx = this.findSelectedItemIdx(aoItem);

            if (selectedItemIdx > -1)
                this.setState(
                    (prevState) => {
                        const selectedItemIdx = this.findSelectedItemIdx(aoItem, prevState);
                        const newSelectedItems = prevState.selectedItems?.slice();
                        if (!_.isNil(newSelectedItems) && (selectedItemIdx > -1))
                            newSelectedItems.splice(selectedItemIdx, 1);

                        return {
                            ...prevState,
                            selectedItems: newSelectedItems,
                        }
                    }, () => this.executeOnSelectedItemsCallback()
                );
            else
                this.setState(
                    (prevState) => {
                        const selectedItemIdx = this.findSelectedItemIdx(aoItem, prevState);
                        const newSelectedItems = prevState.selectedItems?.slice() ?? [];
                        if (selectedItemIdx < 0)
                            newSelectedItems.push(aoItem);

                        return {
                            ...prevState,
                            selectedItems: newSelectedItems,
                        }
                    }, () => this.executeOnSelectedItemsCallback()
                );
        }
        else
            this.setState(
                (prevState) => {
                    return {
                        ...prevState,
                        selectedItems: [aoItem],
                    }
                }, () => this.executeOnSelectedItemsCallback()
            );
    } // onClickItem

    onClearSelection() {
        this.setState(
            (prevState) => {
                return {
                    ...prevState,
                    selectedItems: [],
                }
            }, () => this.executeOnSelectedItemsCallback()
        );
    } // onClearSelection

    onToggleAll() {
        this.setState(
            (prevState) => {
                return {
                    ...prevState,
                    selectedItems: this.state.items,
                }
            }, () => this.executeOnSelectedItemsCallback()
        );
    } // onToggleAll

    render() {
        if (_.isEmpty(this.props.indicators) && _.isEmpty(this.props.indicatorsOfDataset) &&  _.isEmpty(this.props.datasets))
            return null;

        return (
            <div style={{ "width":"100%" }}>
                {
                    this.props.multipleSelection && (
                        <div className="toolbar">
                            <div onClick={() => this.onToggleAll()}>
                                <span className="select-all">
                                    {I18n.t("frontend_text_all")}
                                </span>
                            </div>
                            <div onClick={() => this.onClearSelection()}>
                                <span className="select-none">
                                    {I18n.t("frontend_text_clear_selection")}
                                </span>
                            </div>
                        </div>
                    )
                }
                <div className="panel-select-indicator-container">
                    <ListGroup className="panel-select-indicator">
                        {
                            !_.isNil(this.state.items)
                            && _.map(
                                this.state.items,
                                (aoItem, aiIndex) => {
                                    return (
                                        <ListGroupItem key={aiIndex} onClick={(e) => this.onClickItem(e, aoItem)} className={`${(this.findSelectedItemIdx(aoItem) > -1) ? " selected" : ""}`}>
                                            {
                                                this.props.multipleSelection && (
                                                    <span className="multiple-indicator-selection-container">
                                                        <span className={`multiple-indicator-selection${(this.findSelectedItemIdx(aoItem) > -1) ? " selected" : ""}`}>
                                                        </span>
                                                    </span>
                                                )
                                            }
                                            {
                                                !this.props.multipleSelection && (
                                                    <span className={`indicator-selection${(this.findSelectedItemIdx(aoItem) > -1) ? " selected" : ""}`}>
                                                    </span>
                                                )
                                            }
                                            <span className="indicator-name">
                                                {this.getName(aoItem)}
                                            </span>
                                            <span className="indicator-type-name">
                                                {this.getTypeName(aoItem)}
                                            </span>
                                        </ListGroupItem>
                                    );
                                }
                            )
                        }
                    </ListGroup>
                </div>
            </div>
        );
    } // render    
}


export default PanelSelectIndicator;