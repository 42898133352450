import * as _ from "lodash";
import { models, Report } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { scwApi, scwClientAppApi } from "../../services/Api";
import { CategoryHierarchyDTO } from "../../services/models/CategoryHierarchyDTO";
import { DatasetDTO } from "../../services/models/DatasetDTO";
import { DatasetIndicatorGroupDTO } from "../../services/models/DatasetIndicatorGroupDTO";
import { IndicatorDTO } from "../../services/models/IndicatorDTO";
import { LanguageService } from "../../services/LanguageService";
import Utils from "../../services/Utils";
import Heading from "../Heading";
import PanelBreadcrumb from "../panel/PanelBreadcrumb";
import PanelInfographics from "../panel/PanelInfographics";
import PanelMetadata from "../panel/PanelMetadata";
import PanelRelatedContent from "../panel/PanelRelatedContent";
import PanelSelectData from "../panel/PanelSelectData";
import PanelSelectGeographies from "../panel/PanelSelectGeographies";
import "./PageDataset.css";


type PageDatasetProps = RouteComponentProps<{}> & {
    locale: string[2],
    dataCategory: any,
    dataCategoriesGetOfDataset: Function,
    dataCategoriesGetOfIndicator: Function,
    dataDatasetsGet: Function,
    dataDatasetsGetOfCategory: Function,
    dataPowerBiEmbedInfo: Function,
    dataIndicatorsGet: Function,
    dataIndicatorsGetOfCategory: Function,
    dataIndicatorsGetOfDataset: Function,
    scwApiCategoriesGetOfDataset: Function,
    scwApiCategoriesGetOfIndicator: Function,
    scwApiDatasetsGet: Function,
    scwApiDatasetsGetOfCategory: Function,
    scwApiIndicatorsGet: Function,
    scwApiIndicatorsGetOfCategory: Function,
    scwApiIndicatorsGetOfDataset: Function,
    scwClientAppApiEmbedInfoGetEmbedInfo: Function,
    visualOnlyPath?: string, // if current location path equals value of visualOnlyPath,
                             //  only visual will be rendered(exposed to allow embed PowerBI within articles)
}

type PageDatasetState = {
    category: CategoryHierarchyDTO | undefined, // Category of main indicator or dataset
    dataPowerBiEmbedInfo: any,
    datasetMain: DatasetDTO | null | undefined,
    locale: string[2],
    locationSearch: string,
    indicatorMain: IndicatorDTO | null | undefined,
    parentCategories: CategoryHierarchyDTO[],
    renderVisualOnly: boolean,
    selectedDatasetIdMain: number | null | undefined,
    selectedGeoEntityIDs: number[] | null | undefined,
    selectedIndicatorIdMain: number | null | undefined,
    selectedMeasures: (DatasetIndicatorGroupDTO | IndicatorDTO)[] | null | undefined,
    visualPageName: string | undefined,
}

class PageDataset extends React.Component<PageDatasetProps, PageDatasetState> {
    static readonly VISUAL_ID_MULTI_CHART = "Multi-chart";
    static readonly VISUAL_ID_MULTI_CHART_CYMRAEG = "Multi-chart Cymraeg";

    private renderCount: number = 0;
    report: Report | null = null;

    constructor(props: Readonly<PageDatasetProps>) {
        super(props);

        this.state = {
            category: undefined,
            dataPowerBiEmbedInfo: undefined,
            datasetMain: undefined,
            locale: props.locale,
            locationSearch: "",
            indicatorMain: undefined,
            parentCategories: [],
            renderVisualOnly: false,
            selectedDatasetIdMain: undefined,
            selectedGeoEntityIDs: undefined,
            selectedIndicatorIdMain: undefined,
            selectedMeasures: undefined,
            visualPageName: undefined,
        };
    } // constructor

    downloadVisualData() {
        if (!this.report)
            return;

        this.report.getActivePage().then(
            aoPage => {
                aoPage.getVisuals().then(
                    aoVisualArr => {
                        /*
                         * "clusteredBarChart"
                         * "hundredPercentStackedBarChart"
                         * "lineChart"
                         * "shapeMap" ["barChart"]
                         * "pivotTable"
                         */
                        let liIdx = _.findIndex(aoVisualArr, aoVisual => aoVisual.type === "clusteredBarChart");
                        if (liIdx === -1) liIdx = _.findIndex(aoVisualArr, aoVisual => aoVisual.type === "hundredPercentStackedBarChart");
                        if (liIdx === -1) liIdx = _.findIndex(aoVisualArr, aoVisual => aoVisual.type === "lineChart");
                        if (liIdx === -1) liIdx = _.findIndex(aoVisualArr, aoVisual => aoVisual.type === "shapeMap");
                        if (liIdx === -1) liIdx = _.findIndex(aoVisualArr, aoVisual => aoVisual.type === "pivotTable");

                        if (liIdx > -1) {
                            aoVisualArr[liIdx].exportData(models.ExportDataType.Summarized).then(
                                aoData => {
                                    const asLineArr = aoData.data.split('\r\n');
                                    asLineArr[0] =
                                        asLineArr[0].split(",").map(
                                            asLabel => {
                                                switch (asLabel) {
                                                    case "DatasetIndicators.Languages.Value":
                                                        return "Measure";

                                                    case "IndicatorValue":
                                                        return "Value";

                                                    case "Languages.Value.GeoEntityName":
                                                        return "Geography";

                                                    case "DateRange":
                                                        return "Period";

                                                    case "TemporalCoverages.1.FromDate":
                                                        return "Period";

                                                    // Map
                                                    case "#":
                                                        return "Value";

                                                    case "IndicatorColour":
                                                        return "Quintile_Assumed";

                                                    case "IndicatorColourLegend":
                                                        return "Quintile_Confirmed";

                                                    default:
                                                        return asLabel;
                                                }
                                            }).join(",");

                                    var fileContent = new Blob([asLineArr.join('\r\n')], { type: 'text/plain' });
                                    var anchorElement = document.createElement('a');
                                    window.URL = window.URL || window.webkitURL;
                                    anchorElement.setAttribute("href", window.URL.createObjectURL(fileContent));
                                    anchorElement.setAttribute("download", "export.csv");

                                    anchorElement.style.display = 'none';
                                    document.body.appendChild(anchorElement);
                                    try {
                                        anchorElement.click();
                                    }
                                    finally {
                                        document.body.removeChild(anchorElement);
                                    }
                                }
                            );
                        }
                    }
                );
            }
        );
    } // downloadVisualData

    static getDerivedStateFromProps(nextProps: Readonly<PageDatasetProps>,
        prevState: PageDatasetState): Partial<PageDatasetState> | null {
        // ?c= <- category id
        // ?d= <- dataset id (applies when ?i is not set)
        // ?dig= <- dataset indicator group ids (applies when dataset is set)
        // ?g= <- geography ids [comma separated]
        // ?i= <- indicator id (applies when ?d is not set)
        // ?p= <- parent category chain ids (applies for a category, as the same category may exist on different branches) [comma separated]
        // ?v= <- visual name / chart name

        // Read location's parameters to initialise information where needed
        const loParams = new URLSearchParams(nextProps.location.search);

        // Initialise initial conditions either from props (when state has not been set) or state (when state has been set)
        const lsCategoryId = _.isUndefined(prevState.category) ? loParams.get("c") : String(prevState.category.categoryID);
        const lsDatasetId = _.isUndefined(prevState.selectedDatasetIdMain) ? loParams.get("d") : (_.isNull(prevState.selectedDatasetIdMain) ? "" : String(prevState.selectedDatasetIdMain));
        const lsDatasetIndicatorGroupIds = _.isUndefined(prevState.selectedMeasures) ? loParams.get("dig") : (_.isNull(prevState.selectedMeasures) ? null : prevState.selectedMeasures?.filter((measure) => 'datasetIndicatorGroupID' in measure).map(measure => (measure as DatasetIndicatorGroupDTO).datasetIndicatorGroupID).sort().join(","));
        const lsGeographyIds = _.isUndefined(prevState.selectedGeoEntityIDs) ? loParams.get("g") : (_.isNull(prevState.selectedGeoEntityIDs) ? null : prevState.selectedGeoEntityIDs.sort().join(","));
        const lsIndicatorId = _.isUndefined(prevState.selectedIndicatorIdMain) ? loParams.get("i") : (_.isNull(prevState.selectedIndicatorIdMain) ? "" : String(prevState.selectedIndicatorIdMain));
        const lsParentCategoryIds = loParams.get("p");
        const lsVisualPageName = _.isUndefined(prevState.visualPageName) ? decodeURIComponent(loParams.get("v") ?? "") : prevState.visualPageName;
        const lbRenderVisualOnly = (nextProps.location.pathname === nextProps.visualOnlyPath);

        let powerBIReportName = PageDataset.VISUAL_ID_MULTI_CHART;
        if (nextProps.locale === LanguageService.CYMRAEG) {
            powerBIReportName = PageDataset.VISUAL_ID_MULTI_CHART_CYMRAEG;
        }
                
        // Get/refresh token for PowerBI visual
        var loScwClientAppApiEmbedInfoGetEmbedInfoObject =
            nextProps.dataPowerBiEmbedInfo(powerBIReportName);

        if (
            !loScwClientAppApiEmbedInfoGetEmbedInfoObject.isSuccess
            && !loScwClientAppApiEmbedInfoGetEmbedInfoObject.isLoading
        )
            nextProps.scwClientAppApiEmbedInfoGetEmbedInfo(powerBIReportName);

        // Load information about geographies, if provided within url query part
        let nextStateSelectedGeoEntityIDs: number[] | null | undefined = prevState.selectedGeoEntityIDs;
        if (_.isNil(prevState.selectedGeoEntityIDs) && lsGeographyIds?.trim()) {
            nextStateSelectedGeoEntityIDs = lsGeographyIds.split(",").map(lsGeographyId => parseInt(lsGeographyId)).sort();
        }

        let loCategory: CategoryHierarchyDTO | null;
        let loParentCategories: CategoryHierarchyDTO[];

        let nextStateSelectedDatasetIdMain = prevState.selectedDatasetIdMain;
        let nextStateSelectedIndicatorIdMain = prevState.selectedIndicatorIdMain;

        // Load parent categories
        if (lsParentCategoryIds)
            loParentCategories = Utils.getParentCategoriesByIds(lsParentCategoryIds as string, nextProps.dataCategory.data);
        else
            loParentCategories = [];

        // Load category
        if (loParentCategories.length > 0)
            loCategory = Utils.getCategoryById(lsCategoryId, loParentCategories[loParentCategories.length - 1].children);
        else
            loCategory = Utils.getCategoryById(lsCategoryId, nextProps.dataCategory.data);

        // Consider case when category has not been provided but only indicator or dataset id - information about associated category needs to be loaded
        if (!lsCategoryId && lsIndicatorId) {
            const liIndicatorId = parseInt(lsIndicatorId);

            const loApiObjectCategoriesGetOfIndicator =
                nextProps.dataCategoriesGetOfIndicator(nextProps.locale, liIndicatorId);

            if (
                !loApiObjectCategoriesGetOfIndicator.isSuccess && !loApiObjectCategoriesGetOfIndicator.isLoading
            )
                nextProps.scwApiCategoriesGetOfIndicator(nextProps.locale, liIndicatorId);
            else if (
                !_.isNil(loApiObjectCategoriesGetOfIndicator.data) &&
                loApiObjectCategoriesGetOfIndicator.data.length > 0
            ) {
                const loCategoryHierarchyURLParams =
                    Utils.getCategoriesHierarchyURLParams(loApiObjectCategoriesGetOfIndicator.data[0]);

                nextProps.history.push(`${nextProps.location.pathname}?c=${loCategoryHierarchyURLParams.categoryId}&p=${loCategoryHierarchyURLParams.parentCategoryIds}&i=${liIndicatorId}&d=&dig=&g=${lsGeographyIds}&v=${encodeURIComponent(lsVisualPageName)}`);
            };
        } else if (!lsCategoryId && lsDatasetId) {
            const liDatasetId = parseInt(lsDatasetId);

            const loApiObjectCategoriesGetOfDataset =
                nextProps.dataCategoriesGetOfDataset(nextProps.locale, liDatasetId);

            if (
                !loApiObjectCategoriesGetOfDataset.isSuccess && !loApiObjectCategoriesGetOfDataset.isLoading
            )
                nextProps.scwApiCategoriesGetOfDataset(nextProps.locale, liDatasetId);
            else if (
                !_.isNil(loApiObjectCategoriesGetOfDataset.data) &&
                loApiObjectCategoriesGetOfDataset.data.length > 0
            ) {
                const loCategoryHierarchyURLParams =
                    Utils.getCategoriesHierarchyURLParams(loApiObjectCategoriesGetOfDataset.data[0]);

                nextProps.history.push(`${nextProps.location.pathname}?c=${loCategoryHierarchyURLParams.categoryId}&p=${loCategoryHierarchyURLParams.parentCategoryIds}&i=&d=${lsDatasetId}&dig=${lsDatasetIndicatorGroupIds}&g=${lsGeographyIds}&v=${encodeURIComponent(lsVisualPageName)}`);
            };
        }

        let nextStateSelectedMeasures = prevState.selectedMeasures;

        // If category is present, initialise indicator / dataset of the category
        if (!_.isNil(loCategory)) {
            if (lsIndicatorId) {
                const liIndicatorId = parseInt(lsIndicatorId);

                // Initialise selected indicator, if has not been initialised or provided via url indicator id is different
                if (
                    _.isNil(nextStateSelectedIndicatorIdMain)
                    || nextStateSelectedIndicatorIdMain !== liIndicatorId
                ) {
                    // Try initialise from url parameter
                    nextStateSelectedDatasetIdMain = null;
                    nextStateSelectedIndicatorIdMain = liIndicatorId;
                    nextStateSelectedMeasures = null;
                }
            }
            else if (lsDatasetId) {
                const liDatasetId = parseInt(lsDatasetId);

                // Initialise selected dataset, when has not been initialised or provided via url dataset id differs
                if (
                    _.isNil(nextStateSelectedDatasetIdMain)
                    || nextStateSelectedDatasetIdMain !== liDatasetId
                ) {
                    nextStateSelectedDatasetIdMain = liDatasetId;
                    nextStateSelectedIndicatorIdMain = null;
                    nextStateSelectedMeasures = _.isUndefined(nextStateSelectedMeasures) ? undefined : null; // keep uninitialised (for later initialisation) or reset
                }
            }
            else if (_.isNil(nextStateSelectedIndicatorIdMain) && _.isNil(nextStateSelectedDatasetIdMain)) {
                const liCategoryId = (loCategory as CategoryHierarchyDTO).categoryID;

                // Query datasets of a category - to pick up first
                const loDatasetsGetOfCategoryApiObject =
                    nextProps.dataDatasetsGetOfCategory(nextProps.locale, liCategoryId);

                if (
                    !loDatasetsGetOfCategoryApiObject.isSuccess
                    && !loDatasetsGetOfCategoryApiObject.isLoading
                )
                    nextProps.scwApiDatasetsGetOfCategory(nextProps.locale, liCategoryId);

                if (loDatasetsGetOfCategoryApiObject.isSuccess) {
                    const loDatasetOfCategoryArr = loDatasetsGetOfCategoryApiObject.data;

                    if (loDatasetOfCategoryArr.length > 0) {
                        nextStateSelectedDatasetIdMain = (loDatasetOfCategoryArr[0] as DatasetDTO).datasetID;
                        nextStateSelectedIndicatorIdMain = null;
                    }
                    else {
                        // Query indicators of a category - to pick up first
                        const loApiObjectIndicatorsOfCategory =
                            nextProps.dataIndicatorsGetOfCategory(nextProps.locale, liCategoryId);

                        if (
                            !loApiObjectIndicatorsOfCategory.isSuccess
                            && !loApiObjectIndicatorsOfCategory.isLoading
                        )
                            nextProps.scwApiIndicatorsGetOfCategory(nextProps.locale, liCategoryId);

                        // When data with indicators of category is available, choose first
                        if (!_.isNil(loApiObjectIndicatorsOfCategory.data)
                            && (loApiObjectIndicatorsOfCategory.data.length > 0)
                        ) {
                            nextStateSelectedDatasetIdMain = null;
                            nextStateSelectedIndicatorIdMain =
                                (loApiObjectIndicatorsOfCategory.data[0] as IndicatorDTO).indicatorID;
                        }
                    }
                }
            }
        }

        let nextStateIndicatorMain = null;
        // Ensure selected indicator's data is loaded (when selected indicator has been initialised)
        if (!_.isNil(nextStateSelectedIndicatorIdMain)) {

            // Reload information about indicator; important to refresh when language has changed
            const loApiObject = nextProps.dataIndicatorsGet(nextProps.locale, nextStateSelectedIndicatorIdMain);

            if (loApiObject.isSuccess)
                nextStateIndicatorMain = loApiObject.data[0];
            else if (!loApiObject.isLoading)
                nextProps.scwApiIndicatorsGet(nextProps.locale, nextStateSelectedIndicatorIdMain);
        }

        let nextStateDatasetMain = null;
        // Ensure selected dataset's data is loaded (when selected dataset has been initialised)
        if (!_.isNil(nextStateSelectedDatasetIdMain)) {
            const loApiObject = nextProps.dataDatasetsGet(nextProps.locale, nextStateSelectedDatasetIdMain);

            if (loApiObject.isSuccess)
                nextStateDatasetMain = loApiObject.data[0];
            else if (!loApiObject.isLoading)
                nextProps.scwApiDatasetsGet(nextProps.locale, nextStateSelectedDatasetIdMain);

            // Get dataset indicators
            if (!_.isNil(nextStateDatasetMain) && _.isNil(nextStateSelectedMeasures)) {
                const loApiObjectIndicatorsOfDataset = nextProps.dataIndicatorsGetOfDataset(nextProps.locale, (nextStateDatasetMain as DatasetDTO).datasetID);

                if (loApiObjectIndicatorsOfDataset.isSuccess) {
                    // Process case when selected measures have not been initialised within state or selection has been reset and all measures need be selected (by default)
                    if (_.isUndefined(nextStateSelectedMeasures) && lsDatasetIndicatorGroupIds) {
                        const liDatasetIndicatorGroupIdArr = lsDatasetIndicatorGroupIds.split(",").map(lsDataSetIndicatorGroupId => parseInt(lsDataSetIndicatorGroupId));

                        nextStateSelectedMeasures = (loApiObjectIndicatorsOfDataset.data as DatasetIndicatorGroupDTO[]).filter(aoItem => liDatasetIndicatorGroupIdArr.some(Id => Id === aoItem.datasetIndicatorGroupID));
                    }
                    else if (_.isNull(nextStateSelectedMeasures)) {
                        nextStateSelectedMeasures = (loApiObjectIndicatorsOfDataset.data as DatasetIndicatorGroupDTO[]);
                    }
                }
                else if (!loApiObjectIndicatorsOfDataset.isLoading)
                    nextProps.scwApiIndicatorsGetOfDataset(nextProps.locale, (nextStateDatasetMain as DatasetDTO).datasetID);
            }
        }

        return {
            category: loCategory ?? undefined,
            dataPowerBiEmbedInfo: loScwClientAppApiEmbedInfoGetEmbedInfoObject,
            datasetMain: nextStateDatasetMain,
            indicatorMain: nextStateIndicatorMain,
            locale: nextProps.locale,
            locationSearch: nextProps.location.search,
            parentCategories: loParentCategories,
            renderVisualOnly: lbRenderVisualOnly,
            selectedDatasetIdMain: nextStateSelectedDatasetIdMain,
            selectedGeoEntityIDs: nextStateSelectedGeoEntityIDs,
            selectedIndicatorIdMain: nextStateSelectedIndicatorIdMain,
            selectedMeasures: nextStateSelectedMeasures,
            visualPageName: lsVisualPageName,
        };
    } // getDerivedStateFromProps

    getSelectedIndicatorIDs(): number[] {
        let selectedIndicatorIds: (number | number[])[] = [];

        if (this.state.selectedIndicatorIdMain && this.state.indicatorMain) {
            selectedIndicatorIds.push(this.state.indicatorMain.indicatorID);
        }
        else if (this.state.selectedDatasetIdMain && this.state.datasetMain && this.state.selectedMeasures) {
            selectedIndicatorIds.push(
                ...this.state.selectedMeasures.map(
                    aoItem => {
                        if ('indicatorID' in aoItem)
                            return aoItem.indicatorID;
                        else
                            if ('datasetIndicatorGroupID' in aoItem)
                                return aoItem.indicators.map(aoIndicator => aoIndicator.indicatorID);
                            else
                                throw new Error("Unexpected instance of object.");
                    }
                )
            );
        }

        return _.flatten(selectedIndicatorIds);
    } // getSelectedIndicatorIDs

    getSelectedIndicators(): IndicatorDTO[] {
        let selectedIndicators: (IndicatorDTO | IndicatorDTO[])[] = [];

        if (this.state.selectedIndicatorIdMain && this.state.indicatorMain) {
            selectedIndicators.push(this.state.indicatorMain);
        }
        else if (this.state.selectedDatasetIdMain && this.state.datasetMain && this.state.selectedMeasures) {
            selectedIndicators.push(
                ...this.state.selectedMeasures.map(
                    aoItem => {
                        if ('indicatorID' in aoItem)
                            return aoItem;
                        else
                            if ('datasetIndicatorGroupID' in aoItem)
                                return aoItem.indicators;
                            else
                                throw new Error("Unexpected instance of object.");
                    }
                )
            );
        }

        return _.flatten(selectedIndicators);
    } // getSelectedIndicators

    onSelectGeographiesSelectionChanged(aiGeoEntityIDs: number[]) {
        this.setState(
            prevState => ({
                ...prevState,
                selectedGeoEntityIDs: aiGeoEntityIDs
            }),
            () => {
                this.updateUrlPath();
            }
        );
    } // onSelectGeographiesSelectionChanged

    onPanelSelectDataSelectedItems(aoItemArr: (DatasetDTO | DatasetIndicatorGroupDTO | IndicatorDTO)[]) {
        if (aoItemArr.length !== 1)
            throw new Error(`Unexpected state: ${aoItemArr.length} items have been selected.`);

        const loItem = aoItemArr[0];
        if ('datasetIndicatorGroupID' in loItem)
            throw new Error("Unexpected state: dataset indicator group has been selected.");

        const loParams = new URLSearchParams(this.props.location.search);

        // Consider case when an indicator has been selected
        const lsIndicatorId = loParams.get("i");
        if ('indicatorID' in loItem && lsIndicatorId != String(loItem.indicatorID)) {
            this.setState(
                prevState => ({
                    ...prevState,
                    datasetMain: undefined,
                    indicatorMain: undefined,
                    selectedDatasetIdMain: null,
                    selectedIndicatorIdMain: loItem.indicatorID,
                    selectedMeasures: null,
                }),
                () => {
                    this.updateUrlPath();
                }
            );          


            return;
        }

        // Consider case when a dataset has been selected
        const lsDatasetId = loParams.get("d");        
        if ('datasetID' in loItem && lsDatasetId != String(loItem.datasetID))
            this.setState(
                prevState => ({
                    ...prevState,
                    datasetMain: undefined,
                    indicatorMain: undefined,
                    selectedDatasetIdMain: loItem.datasetID,
                    selectedIndicatorIdMain: null,
                    selectedMeasures: null,
                }),
                () => {
                    this.updateUrlPath();
                }
            );
    } // onPanelSelectDataSelectedItems

    onPanelSelectData2SelectedItems(aoItemArr: (DatasetDTO | DatasetIndicatorGroupDTO | IndicatorDTO)[]) {
        if (aoItemArr.some(aoItem => !('indicatorID' in aoItem) && !('datasetIndicatorGroupID' in aoItem)))
            throw new Error(`Unexpected items have been selected - ${aoItemArr}`);

        this.setState(
            (state) => {
                return {
                    ...state,
                    selectedMeasures: aoItemArr as (IndicatorDTO | DatasetIndicatorGroupDTO)[],
                }
            },
            () => {
                this.updateUrlPath();
            }
        );
    } // onPanelSelectData2SelectedItems

    render() {
        if (_.isNil(this.state.category))
          return null;

        this.renderCount += 1;

        window.dataLayer = [];
        window.dataLayer.push({
            language: this.state.locale,
            mainIndicator: this.state.indicatorMain,
            category: this.state.category,
            parentCategories: this.state.parentCategories,
            geoEntities: this.state.selectedGeoEntityIDs
        });


        // Check whether visual needs be rendered only - that's to support view of visual within articles
        if (this.state.renderVisualOnly) {
            if (this.state.selectedIndicatorIdMain && this.state.indicatorMain)
                return this.renderVisual();
            else
                return null;
        }

        const liSelectedIndicatorIDs = this.getSelectedIndicatorIDs();
        let metadataIndicatorID;
        if (!_.isNil(this.state.indicatorMain)) {
            metadataIndicatorID = (this.state.indicatorMain as IndicatorDTO).indicatorID;
        }
        else if (liSelectedIndicatorIDs.length > 0) {
            metadataIndicatorID = liSelectedIndicatorIDs[0];
        }
        const metadataIndicators = this.getSelectedIndicators();

        return (
            <div className="page-dataset">
                <PanelBreadcrumb className="page-dataset-breadcrumb" />

                <Heading
                    align="left"
                    className="page-dataset-heading"
                    description={this.state.category.categoryDescription}
                    title={this.state.category.categoryName}
                />

                <PanelInfographics groupId={`category_${this.state.category.categoryID}`} />

                <div className="page-dataset-section">
                    <div className="thick-bar"></div>

                    <PanelSelectGeographies
                        onSelectionChanged={(aiGeoEntityIDs: number[]) => this.onSelectGeographiesSelectionChanged(aiGeoEntityIDs)}
                        selectedGeoEntityIDs={this.state.selectedGeoEntityIDs ?? null}
                    />

                    {
                        (
                            (
                                (!_.isNil(this.state.selectedIndicatorIdMain))
                                && (!_.isNil(this.state.indicatorMain))
                            ) || (
                                (!_.isNil(this.state.selectedDatasetIdMain))
                                && (!_.isNil(this.state.datasetMain))
                            )
                        )
                        && (
                            <>
                                <div className="thick-bar"></div>
                                <PanelSelectData
                                    allowSearch={false}
                                    categoryId={(this.state.category as CategoryHierarchyDTO).categoryID}
                                    dataset={this.state.datasetMain ?? undefined}
                                    indicator={this.state.indicatorMain ?? undefined}
                                    onSelectedItems={(aoItemArr: (DatasetDTO | DatasetIndicatorGroupDTO | IndicatorDTO)[]) => this.onPanelSelectDataSelectedItems(aoItemArr)}
                                    />
                            </>
                        )
                    }

                    {
                        (!_.isNil(this.state.selectedDatasetIdMain))
                        && (!_.isNil(this.state.datasetMain))
                        && (
                            <>
                                <div className="thick-bar"></div>
                                <PanelSelectData
                                    allowSearch={false}
                                    allowSelectOnlyMeasures
                                    categoryId={(this.state.category as CategoryHierarchyDTO).categoryID}
                                    datasetContext={this.state.datasetMain}
                                    datasetIndicators={this.state.selectedMeasures as DatasetIndicatorGroupDTO[]}
                                    indicator={this.state.selectedMeasures && this.state.selectedMeasures.length > 0 ? (('indicatorID' in this.state.selectedMeasures[0]) ? this.state.selectedMeasures[0] : undefined) : undefined}
                                    multipleSelection
                                    onSelectedItems={(aoItemArr: (DatasetDTO | DatasetIndicatorGroupDTO | IndicatorDTO)[]) => this.onPanelSelectData2SelectedItems(aoItemArr)}
                                />
                            </>
                            )
                    }

                    <div className="splitter wide"></div>
                    {
                        (
                            !_.isNil(this.state.dataPowerBiEmbedInfo)
                            && this.state.dataPowerBiEmbedInfo.isSuccess
                            && (liSelectedIndicatorIDs.length > 0)
                            && (!_.isNil(this.state.selectedGeoEntityIDs) && this.state.selectedGeoEntityIDs.length > 0)
                            && (
                                <div>
                                    {
                                        // Download is available in development
                                        (process.env.REACT_APP_ENV == 'development') && (
                                            <div className="page-dataset-visual-selection-container">
                                                <button onClick={() => this.downloadVisualData()}>
                                                   (TEST!) Download data
                                                </button>
                                            </div>
                                        )
                                    }

                                    { this.renderVisual() }
                                </div>
                            )
                        )
                    }
                    <div className="splitter wide"></div>

                    <PanelRelatedContent categoryID={this.state.category.categoryID}/>
                    {
                        !_.isNil(metadataIndicatorID) && (
                            <div>
                                <div className="splitter"></div>

                                <PanelMetadata
                                    indicatorID={metadataIndicatorID}
                                    indicators={metadataIndicators}
                                    />
                            </div>
                        )
                    }
                </div>
            </div>
        );
    } // render

    renderVisual() {
        const liSelectedIndicatorIDs = this.getSelectedIndicatorIDs();

        if (_.isNil(this.state.dataPowerBiEmbedInfo)
            || !this.state.dataPowerBiEmbedInfo.isSuccess
            || liSelectedIndicatorIDs.length < 1
            || _.isNil(this.state.selectedGeoEntityIDs)
            || this.state.selectedGeoEntityIDs.length < 1
        )
            return null;

        const mainComponentReference = this;

        return (
            <PowerBIEmbed
                cssClassName={this.state.renderVisualOnly ? "powerbi-embed-container-visual-only" : "powerbi-embed-container"}

                embedConfig={{
                    accessToken: this.state.dataPowerBiEmbedInfo.data.EmbedToken.Token,
                    embedUrl: this.state.dataPowerBiEmbedInfo.data.EmbedReport[0].EmbedUrl,
                    filters: [
                        {
                            $schema: "http://powerbi.com/product/schema#basic",
                            target: {
                                table: "Indicators",
                                column: "IndicatorID"
                            },
                            operator: "In",
                            values: liSelectedIndicatorIDs,
                            filterType: 1,
                            requireSingleSelection: false,
                            displaySettings: {
                                isLockedInViewMode: true,
                                isHiddenInViewMode: true,
                            }
                        },
                        {
                            $schema: "http://powerbi.com/product/schema#basic",
                            target: {
                                table: "Indicators",
                                column: "Languages.Code.Name"
                            },
                            operator: "In",
                            values: [this.props.locale],
                            filterType: 1,
                            requireSingleSelection: false,
                            displaySettings: {
                                isLockedInViewMode: true,
                                isHiddenInViewMode: true,
                            }
                        },
                        {
                            $schema: "http://powerbi.com/product/schema#basic",
                            target: {
                                table: "Geographies",
                                column: "GeoEntityID"
                            },
                            operator: "In",
                            values: this.state.selectedGeoEntityIDs.concat([30] /* Wales */),
                            filterType: 1,
                            requireSingleSelection: false,
                            displaySettings: {
                                isLockedInViewMode: true,
                                isHiddenInViewMode: true,
                            }
                        },
                        {
                            $schema: "http://powerbi.com/product/schema#basic",
                            target: {
                                table: "Geographies",
                                column: "Languages.Code.GeoEntityName"
                            },
                            operator: "In",
                            values: [this.props.locale],
                            filterType: 1,
                            requireSingleSelection: false,
                            displaySettings: {
                                isLockedInViewMode: true,
                                isHiddenInViewMode: true,
                            }
                        }
                    ],
                    id: this.state.dataPowerBiEmbedInfo.data.EmbedReport[0].ReportId,
                    pageName: this.state.visualPageName ?? undefined,
                    settings: {
                        filterPaneEnabled: false,
                        navContentPaneEnabled: false,
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false
                            }
                        },
                        // background: models.BackgroundType.Transparent,
                    },
                    tokenType: models.TokenType.Embed,
                    type: "report",   // Supported types: report, dashboard, tile, visual and qna
                }}

                getEmbeddedComponent={
                    (embeddedReport: any) => {
                        this.report = embeddedReport as Report;

                        this.report.on("commandTriggered", function (event) {
                            // console.log("(PageDataset.renderVisual -> getEmbeddedComponent) commandTriggered", event);
                        });

                        this.report.on("dataSelected", function (event) {
                            // console.log("(PageDataset.renderVisual -> getEmbeddedComponent) dataSelected", event);
                        });

                        this.report.on("error", function () {
                            // console.log("(PageDataset.renderVisual -> getEmbeddedComponent) error");
                        });

                        this.report.on("loaded", function () {
                            // console.log("(PageDataset.renderVisual -> getEmbeddedComponent) loaded");
                        });

                        this.report.on("pageChanged", function (event) {
                            // console.log("(PageDataset.renderVisual -> getEmbeddedComponent) pageChanged", event);
                        });

                        this.report.on("rendered", function () {
                            // console.log('(PageDataset.renderVisual -> getEmbeddedComponent) rendered', r);
                        });

                        this.report.on("visualClicked", function (event) {
                            // console.log("(PageDataset.renderVisual -> getEmbeddedComponent) visualClicked", event);
                        });

                        this.report.on("visualRendered", function (event) {
                            // console.log("(PageDataset.renderVisual -> getEmbeddedComponent) visualRendered", event);
                        });

                        this.report.on("selectionChanged", function (event) {
                            // console.log("(PageDataset.renderVisual -> getEmbeddedComponent) selectionChanged", event);
                        });
                    }
                }

                key={this.renderCount}
            />
        )
    } // renderVisual

    updateUrlPath() {
        const loParams = new URLSearchParams(this.props.location.search);

        // At least categories
        const lsCategoryId = this.state.category ? String(this.state.category.categoryID) : loParams.get("c");
        if (!lsCategoryId || (!this.state.selectedIndicatorIdMain && !this.state.selectedDatasetIdMain))
            return;
        
        const lsDatasetId = this.state.selectedDatasetIdMain ?? "";
        const lsDatasetIndicatorGroupIds = this.state.selectedMeasures?.filter((measure) => 'datasetIndicatorGroupID' in measure).map(measure => (measure as DatasetIndicatorGroupDTO).datasetIndicatorGroupID).sort().join(",") ?? "";
        const lsGeographyIds = this.state.selectedGeoEntityIDs ? this.state.selectedGeoEntityIDs.sort().join(",") : "";
        const lsIndicatorId = this.state.selectedIndicatorIdMain ?? "";
        const lsParentCategories = loParams.get("p");
        const lsVisualPageName = this.state.visualPageName ?? decodeURIComponent(loParams.get("v") ?? "");

        const lsNewPath =
            `${this.props.location.pathname}?` +
                `c=${lsCategoryId}` +
                `&p=${lsParentCategories}` +
                `&i=${lsIndicatorId}` +
                `&d=${lsDatasetId}` +
                `&dig=${lsDatasetIndicatorGroupIds}` +
                `&g=${lsGeographyIds}` +
                `&v=${encodeURIComponent(lsVisualPageName)}`;

        if (lsNewPath !== `${this.props.location.pathname}${this.props.location.search}`)
            this.props.history.push(lsNewPath);
    } // updateUrlPath
};

// Link with Redux store

const mapStateToProps =
    (state: any) => (
        {
            locale: state.i18n.locale,
            dataCategory: scwApi.endpoints.categoriesGetHierarchyAll.select(state.i18n.locale)(state),
            dataCategoriesGetOfDataset: (languageCode: string, datasetId: number) => scwApi.endpoints.categoriesGetOfDataset.select({ "languageCode": languageCode, "datasetId": datasetId })(state),
            dataCategoriesGetOfIndicator: (languageCode: string, indicatorId: number) => scwApi.endpoints.categoriesGetOfIndicator.select({ "languageCode": languageCode, "indicatorId": indicatorId })(state),
            dataDatasetsGet: (languageCode: string, id: number) => scwApi.endpoints.datasetsGet.select({ "languageCode": languageCode, "id": id })(state),
            dataDatasetsGetOfCategory: (languageCode: string, categoryId: number) => scwApi.endpoints.datasetsGetOfCategory.select({ "languageCode": languageCode, "categoryId": categoryId })(state),
            dataIndicatorsGet: (languageCode: string, id: number) => scwApi.endpoints.indicatorsGet.select({ "languageCode": languageCode, "id": id })(state),
            dataIndicatorsGetOfCategory: (languageCode: string, categoryId: number) => scwApi.endpoints.indicatorsGetOfCategory.select({ "languageCode": languageCode, "categoryId": categoryId })(state),
            dataIndicatorsGetOfDataset: (languageCode: string, datasetId: number) => scwApi.endpoints.indicatorsGetOfDataset.select({ "languageCode": languageCode, "datasetId": datasetId })(state),
            dataPowerBiEmbedInfo: (reportIdName: string) => scwClientAppApi.endpoints.embedInfoGetEmbedInfo.select(reportIdName)(state),
        }
    );

const mapDispatchToProps =
    (dispatch: Function) => {
        return {
            scwApiCategoriesGetOfDataset: (languageCode: string, datasetId: number) => dispatch(scwApi.endpoints.categoriesGetOfDataset.initiate({ languageCode: languageCode, datasetId: datasetId })),
            scwApiCategoriesGetOfIndicator: (languageCode: string, indicatorId: number) => dispatch(scwApi.endpoints.categoriesGetOfIndicator.initiate({ languageCode: languageCode, indicatorId: indicatorId })),
            scwApiDatasetsGet: (languageCode: string, id: number) => dispatch(scwApi.endpoints.datasetsGet.initiate({ languageCode: languageCode, id: id })),
            scwApiDatasetsGetOfCategory: (languageCode: string, categoryId: number) => dispatch(scwApi.endpoints.datasetsGetOfCategory.initiate({ languageCode, categoryId })),
            scwApiIndicatorsGet: (languageCode: string, id: number) => dispatch(scwApi.endpoints.indicatorsGet.initiate({ languageCode: languageCode, id: id })),
            scwApiIndicatorsGetOfCategory: (languageCode: string, categoryId: number) => dispatch(scwApi.endpoints.indicatorsGetOfCategory.initiate({ languageCode: languageCode, categoryId: categoryId })),
            scwApiIndicatorsGetOfDataset: (languageCode: string, datasetId: number) => dispatch(scwApi.endpoints.indicatorsGetOfDataset.initiate({ languageCode: languageCode, datasetId: datasetId })),
            scwClientAppApiEmbedInfoGetEmbedInfo: (reportIdName: string) => dispatch(scwClientAppApi.endpoints.embedInfoGetEmbedInfo.initiate(reportIdName)),
        }
    };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageDataset));
