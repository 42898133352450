import * as React from "react";
import { Container } from "reactstrap";
import HeaderBarTop from "./HeaderBarTop";
import HeaderBarBottom from "./HeaderBarBottom";
import Footer from "./Footer";
import NavMenu from "./NavMenu";


export default class Layout extends React.PureComponent<{}, { children?: React.ReactNode }> {
    public render() {
        return (
            <React.Fragment>
                <HeaderBarTop />
                <HeaderBarBottom />
                <NavMenu />
                <Container className="page-container">
                    {this.props.children}
                </Container>
                <Footer />
            </React.Fragment>
        );
    }
}