export class LanguageService {

    static CYMRAEG: string[2] = "cy";
    static ENGLISH: string[2] = "en";

    private static instance: LanguageService;

    static getInstance(): LanguageService {
        if (!LanguageService.instance) {
            LanguageService.instance = new LanguageService();
        }

        return LanguageService.instance;
    } // getInstance

} // getTranslations