import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import * as _ from "lodash";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { scwApi } from "../../services/Api";
import Utils from "../../services/Utils";
import { CategoryHierarchyDTO } from "../../services/models/CategoryHierarchyDTO";

import "./PanelBreadcrumb.css";


type PanelBreadcrumbProps = {
    className?: string,
    dataCategory: any,
    locale: string[2],
}

type PanelBreadcrumbState = {
    category: CategoryHierarchyDTO | null,
    locale: string[2],
    locationSearch: string,
    parentCategories: CategoryHierarchyDTO[],
}

class PanelBreadcrumb extends React.Component<RouteComponentProps<{}> & PanelBreadcrumbProps, PanelBreadcrumbState> {

    constructor(props: Readonly<RouteComponentProps<{}> & PanelBreadcrumbProps>) {
        super(props);

        this.state = {
            category: null,
            locale: props.locale,
            locationSearch: "",
            parentCategories: []
        };
    } // constructor

    static getDerivedStateFromProps(nextProps: Readonly<RouteComponentProps<{}> & PanelBreadcrumbProps>,
        prevState: PanelBreadcrumbState): Partial<PanelBreadcrumbState> | null {

        if ((nextProps.location.search === prevState.locationSearch) && (nextProps.locale === prevState.locale))
            return null; // No state changes

        // Read location's parameters to get category and its parents
        const loParams = new URLSearchParams(nextProps.location.search);

        const lsCategoryId = loParams.get("c");
        const lsParentCategories = loParams.get("p");

        let loCategory: CategoryHierarchyDTO | null;
        let loParentCategories: CategoryHierarchyDTO[];

        // load parent categories
        if (lsParentCategories)
            loParentCategories = Utils.getParentCategoriesByIds(lsParentCategories as string, nextProps.dataCategory.data);
        else
            loParentCategories = [];

        // load category
        if (loParentCategories.length > 0)
            loCategory = Utils.getCategoryById(lsCategoryId, loParentCategories[loParentCategories.length - 1].children);
        else
            loCategory = Utils.getCategoryById(lsCategoryId, nextProps.dataCategory.data);

        return {
            category: loCategory,
            locale: nextProps.locale,
            locationSearch: nextProps.location.search,
            parentCategories: loParentCategories,
        };
    } // getDerivedStateFromProps

    navigate = (asUrl: string) => {
        this.props.history.push(asUrl);
    } // navigate

    render() {
        if (_.isNil(this.state.category))
            return null;

        return (
            <div className={this.props.className}>
                <Breadcrumb listTag="div">
                    <BreadcrumbItem tag="a" onClick={() => this.navigate("/")}>{I18n.t("frontend_menuItem_Home")}</BreadcrumbItem>
                    {
                        this.state.parentCategories.map(
                            (aoParentCategory: CategoryHierarchyDTO, aiIndex: number) => {
                                return (
                                    <BreadcrumbItem
                                        tag="a"
                                        key={aoParentCategory.categoryID}
                                        onClick={() => this.navigate(`/category?c=${aoParentCategory.categoryID}` + (aiIndex > 0 ? `&p=${this.state.parentCategories.slice(0, aiIndex).map((c: CategoryHierarchyDTO) => c.categoryID).join(",")}` : ""))}>
                                        {aoParentCategory.categoryName}
                                    </BreadcrumbItem>
                                );
                            }
                        )
                    }
                    <BreadcrumbItem active tag="span">{this.state.category.categoryName}</BreadcrumbItem>
                </Breadcrumb>
            </div>
        );
    } // render
}

// Link with Redux store

const mapStateToProps =
    (state: any) => (
        {
            locale: state.i18n.locale,
            dataCategory: scwApi.endpoints.categoriesGetHierarchyAll.select(state.i18n.locale)(state),
        }
    );

export default connect(mapStateToProps)(withRouter(PanelBreadcrumb));