import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { withRouter, RouteComponentProps } from "react-router-dom";
import * as _ from "lodash";
import { scwApi } from "../../services/Api";
import Utils from "../../services/Utils";
import { CategoryHierarchyDTO } from "../../services/models/CategoryHierarchyDTO";
import Heading from "../Heading";
import PanelBreadcrumb from "../panel/PanelBreadcrumb";
import PanelDataCategory from "../panel/PanelDataCategory";
import PanelFrequentlyVisited from "../panel/PanelFrequentlyVisited";
import PanelInfographics from "../panel/PanelInfographics";
import PanelUpdates from "../panel/PanelUpdates";

import "./PageCategory.css";


type PageCategoryProps = RouteComponentProps<{}> & {
    locale: string[2],
    dataCategory: any,
}

type PageCategoryState = {
    category: CategoryHierarchyDTO | null,
    locale: string[2],
    locationSearch: string,
    parentCategories: CategoryHierarchyDTO[],
}

class PageCategory extends React.Component<PageCategoryProps, PageCategoryState> {

    constructor(props: Readonly<PageCategoryProps>) {
        super(props);

        this.state = {
            category: null,
            locale: "",
            locationSearch: "",
            parentCategories: []
        };
    } // constructor

    static getDerivedStateFromProps(nextProps: Readonly<PageCategoryProps>,
        prevState: PageCategoryState): Partial<PageCategoryState> | null {

        if ((nextProps.location.search === prevState.locationSearch) && (nextProps.locale === prevState.locale))
            return null; // No state changes

    // Read location's parameters to get category and its parents
        const loParams = new URLSearchParams(nextProps.location.search);

        const lsCategoryId = loParams.get("c");
        const lsParentCategories = loParams.get("p");

        let loCategory: CategoryHierarchyDTO | null;
        let loParentCategories: CategoryHierarchyDTO[];

        // load parent categories
        if (lsParentCategories)
            loParentCategories = Utils.getParentCategoriesByIds(lsParentCategories as string, nextProps.dataCategory.data);
        else
            loParentCategories = [];

        // load category
        if (loParentCategories.length > 0)
            loCategory = Utils.getCategoryById(lsCategoryId, loParentCategories[loParentCategories.length - 1].children);
        else
            loCategory = Utils.getCategoryById(lsCategoryId, nextProps.dataCategory.data);

        return {
            category: loCategory,
            locale: nextProps.locale,
            locationSearch: nextProps.location.search,
            parentCategories: loParentCategories,
        };
    } // getDerivedStateFromProps

    render() {
        if (_.isNil(this.state.category))
            return null;

        return (
            <div className="page-category">
                <PanelBreadcrumb className="page-category-breadcrumb" />

                <Heading
                    align="left"
                    className="page-category-heading"
                    description={this.state.category.categoryDescription}
                    title={this.state.category.categoryName}
                />

                <PanelInfographics groupId={`category_${this.state.category.categoryID}`} />

                <div className="page-category-section">
                    <div className="panel-container-updates-frequently-visited">
                        <div className="panel-container-updates">
                            <PanelUpdates />
                        </div>

                        <div className="panel-container-frequently-visited">
                            <PanelFrequentlyVisited />
                        </div>
                    </div>

                    <PanelDataCategory
                        category={this.state.category}
                        enableOverviewLink={false}
                        enableRowLimit={false}
                        items={
                            _.orderBy<CategoryHierarchyDTO>(
                                this.state.category.children,
                                ["displayOrder", "categoryName"],
                                ["asc", "asc"]
                            )
                        }
                        parentCategories={this.state.parentCategories} />
                </div>
            </div>
        );
    } // render
};

// Link with Redux store

const mapStateToProps =
    (state: any) => (
        {
            locale: state.i18n.locale,
            dataCategory: scwApi.endpoints.categoriesGetHierarchyAll.select(state.i18n.locale)(state),
        }
    );

export default connect(mapStateToProps)(withRouter(PageCategory));