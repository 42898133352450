import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import * as _ from "lodash";
import { Table } from "reactstrap";
import Utils from "../../services/Utils";
import { CategoryHierarchyDTO } from "../../services/models/CategoryHierarchyDTO";
import "./PanelDataCategory.css";


type PanelDataCategoryProps = {    
    locale: string[2],
    category: CategoryHierarchyDTO,
    enableArticles?: boolean,
    enableGoToFullList?: boolean,
    enableOverviewLink: boolean,
    enableRowLimit?: boolean,    
    items: any[],
    onRenderItem?: (aoItem: any) => void,
    onGoToFullList?: () => void,
    parentCategories: CategoryHierarchyDTO[],
};

type PanelDataCategoryState = {
    isExpanded: boolean,
    items: any[],
}

class PanelDataCategory extends React.Component<PanelDataCategoryProps, PanelDataCategoryState> {
    constructor(props: Readonly<PanelDataCategoryProps>) {
        super(props);

        this.state = {
            isExpanded: false,
            items: [],
        };
    } // constructor

    static getDerivedStateFromProps(nextProps: Readonly<PanelDataCategoryProps>, prevState: PanelDataCategoryState): Partial<PanelDataCategoryState> | null {
        let nextItems = nextProps.enableArticles ? nextProps.items.concat({ type: 'articles' }) : nextProps.items;

        return {
            isExpanded: prevState.isExpanded,
            items: nextItems,
        };
    } // getDerivedStateFromProps

    private onGoToFullListClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();

        if (this.props.onGoToFullList)
            this.props.onGoToFullList();
    } // onGoToFullListClick

    private onMoreLessClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();

        this.setState(
            {
                isExpanded: !this.state.isExpanded,
                items: this.state.items,
            }
        );
    } // onMoreLessClick

    private onRenderItem = (aoItem: any) => {
        if (this.props.onRenderItem)
            return this.props.onRenderItem(aoItem);

        if (!aoItem.type) {
            return (
                <div key={aoItem.categoryID} className="category-subcategory">
                    <Link to={{ pathname: ((aoItem.children.length > 0) ? `/category` : `/dataset`), search: `c=${aoItem.categoryID}&p=${Utils.getCategoriesIdsAsString(this.props.parentCategories, this.props.category)}` }}>
                        <span className="subcategory-name">
                            {aoItem.categoryName}
                        </span>
                    </Link>

                    <span className="subcategory-chevron chevron right">
                    </span>
                </div>
            )
        }
        else
            if (aoItem.type == 'articles') {
                return (
                    <div key='articles-subcategory' className="category-subcategory">
                        <Link to={{ pathname: `/articles-list`, search: `c=${this.props.category.categoryID}` }}>
                            <span className="subcategory-name">
                                {I18n.t("frontend_text_Articles")}
                            </span>
                        </Link>

                        <span className="subcategory-chevron chevron right">
                        </span>
                    </div>
                )
            }
            else
                return null;
    } // onRenderItem    

    render() {
        return (
            <div className="category-container">
                <div className="category-main-bar"></div>
                <Table borderless>
                    <tbody>
                    <tr>
                        <td className="cell-category">
                            <span className="category-name">
                                {this.props.category.categoryName}
                            </span>
                            {
                                (
                                    this.props.enableOverviewLink && (
                                        <Link to={{ pathname: ((this.props.category.children.length > 0) ? `/category` : `/dataset`), search: `c=${this.props.category.categoryID}` }}>
                                            <span className="category-overview">
                                                {I18n.t("frontend_panel_category_text_Overview")}
                                            </span>
                                        </Link>
                                    )
                                )
                            }
                        </td>
                        <td className="cell-category-subcategories">
                            {
                                // Render categories
                                _.take(
                                    this.state.items,
                                    ((!this.props.enableRowLimit && !this.props.enableGoToFullList) || this.state.isExpanded) ? this.state.items.length : 6
                                    ).map(
                                        (aoItem) => this.onRenderItem(aoItem)
                                    )
                            }

                            {
                                // Render "See less" or "See more" option
                                (this.state.items.length > 6) && (
                                    (this.props.enableRowLimit) && (
                                        (
                                            (!this.state.isExpanded) && (
                                                <div className="category-subcategory-more-less" onClick={(e) => this.onMoreLessClick(e)}>
                                                    <span className="more-less-text">
                                                        {I18n.t("frontend_panel_category_text_See_more")}
                                                    </span>
                                                    <span className="more-less-chevron chevron bottom">
                                                    </span>
                                                </div>
                                            )
                                        ) || (
                                            (this.state.isExpanded) && (
                                                <div className="category-subcategory-more-less" onClick={(e) => this.onMoreLessClick(e)}>
                                                    <span className="more-less-text">
                                                        {I18n.t("frontend_panel_category_text_See_less")}
                                                    </span>
                                                    <span className="more-less-chevron chevron">
                                                    </span>
                                                </div>
                                            )
                                        )
                                    )
                                )
                            }

                            {
                                // Render "Go to full list" option
                                (this.state.items.length > 6) && (
                                    (this.props.enableGoToFullList) && (
                                        <div className="category-subcategory-more-less" onClick={(e) => this.onGoToFullListClick(e)}>
                                            <span className="more-less-text">
                                                {I18n.t("frontend_text_GoToFullList")}
                                            </span>
                                            <span className="more-less-chevron chevron right">
                                            </span>
                                        </div>
                                    )
                                )
                            }
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
        );
    } // render

}

// Link with Redux store

const mapStateToProps =
    (state: any) => (
        {
            locale: state.i18n.locale,
        }
    );

export default connect(mapStateToProps)(PanelDataCategory);