import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import * as _ from "lodash";
import { Table } from "reactstrap";
import { scwApi } from "../../services/Api";
import { IndicatorDTO } from "../../services/models/IndicatorDTO";
import { IndicatorTypeDTO } from "../../services/models/IndicatorTypeDTO";
import ButtonCustomGroup from "../ButtonCustomGroup";
import PanelIndicatorTile from "./PanelIndicatorTile";
import "./PanelRelatedContent.css";


type PanelRelatedContentProps = {
    categoryID: number,
    dataIndicatorsGetOfCategory: any,
    dataIndicatorsGetTypes: any,
    locale: string[2],
    scwApiIndicatorsGetOfCategory: Function,
    scwApiIndicatorsGetTypes: Function,
};

type PanelRelatedContentState = {
    locale: string[2],
    indicators: IndicatorDTO[],
    selectedType: IndicatorTypeDTO | null
    types: IndicatorTypeDTO[] | [null] | null,
}

class PanelRelatedContent extends React.Component<PanelRelatedContentProps, PanelRelatedContentState> {

    constructor(props: Readonly<PanelRelatedContentProps>) {
        super(props);

        this.state = {
            locale: props.locale,
            indicators: [],
            types: null,
            selectedType: null,
        }
    } // constructor

    static getDerivedStateFromProps(nextProps: Readonly<PanelRelatedContentProps>, prevState: PanelRelatedContentState):
        Partial<PanelRelatedContentState> | null {
        const loScwApiObjectDataIndicatorsGetTypes = nextProps.dataIndicatorsGetTypes;
        const loScwApiObjectDataIndicatorsGetOfCategory =
            nextProps.dataIndicatorsGetOfCategory(nextProps.locale, nextProps.categoryID);

        if (
            (nextProps.locale !== prevState.locale)
                || (
                    !loScwApiObjectDataIndicatorsGetOfCategory.isLoading
                    && !loScwApiObjectDataIndicatorsGetOfCategory.isSuccess
                )
        )
            nextProps.scwApiIndicatorsGetOfCategory(nextProps.locale, nextProps.categoryID);

        if (
            (nextProps.locale !== prevState.locale)
                || (
                    !loScwApiObjectDataIndicatorsGetTypes.isLoading
                    && !loScwApiObjectDataIndicatorsGetTypes.isSuccess
                )
        )
            nextProps.scwApiIndicatorsGetTypes(nextProps.locale);

        const nextStateIndicators =
            _.filter<IndicatorDTO>(
                (loScwApiObjectDataIndicatorsGetOfCategory.isSuccess ? loScwApiObjectDataIndicatorsGetOfCategory.data : []),
                aoIndicator => (aoIndicator.indicatorTypeID === (_.isNil(prevState.selectedType) ? aoIndicator.indicatorTypeID : prevState.selectedType.indicatorTypeID))
            );

        return {
            indicators: nextStateIndicators,
            locale: nextProps.locale,
            types: (loScwApiObjectDataIndicatorsGetTypes.isSuccess ? loScwApiObjectDataIndicatorsGetTypes.data : null),
        }
    } // getDerivedStateFromProps

    onTypeGetCaption(aoObject: IndicatorTypeDTO | null) {
        return (_.isNil(aoObject) ? I18n.t("frontend_text_all") : aoObject.indicatorTypeName);
    } // onTypeGetCaption

    onTypeSelected(aoObject: IndicatorTypeDTO | null) {
        this.setState({
            selectedType: aoObject
        });
    } // onTypeSelected

    render() {
        return (
            <Table borderless className="panel-related-content">
                <tbody>
                <tr>
                    <td className="panel-title">
                        {I18n.t("frontend_related_context_panel_title")}
                    </td>
                    <td className="panel-metadata-cell-group-selection">
                        <div className="type-selection">
                            <ButtonCustomGroup
                                objects={_.isNil(this.state.types) ? [] : _.concat(null, this.state.types)}
                                onGetCaption={(aoObject: any) => this.onTypeGetCaption(aoObject)}
                                onSelected={(aoObject: any) => this.onTypeSelected(aoObject)}
                                selectedObject={this.state.selectedType}/>
                        </div>
                    </td>
                </tr>
                {
                    [0, 1].map(
                        aiRowIndex => {
                            return (
                                <tr key={aiRowIndex}>
                                    <td></td>
                                    <td className="panel-related-content-cell-tiles">
                                        {
                                            [0, 1, 2].map(
                                                aiColIndex => {
                                                    const liIndex = aiRowIndex * 3 + aiColIndex;
                                                    const loIndicator =
                                                        (this.state.indicators.length > liIndex)
                                                            ? this.state.indicators[liIndex]
                                                            : null;

                                                    if (!_.isNil(loIndicator))
                                                        return (
                                                            <PanelIndicatorTile
                                                                indicator={loIndicator} key={`${aiColIndex}_${loIndicator.indicatorID}`} renderCircleShape={false} />
                                                        );
                                                    else
                                                        return null;
                                                }
                                            )
                                        }
                                    </td>
                                </tr>
                            );
                        }
                )
                }
                </tbody>
            </Table>
        );
    } // render
}


// Link with Redux store

const mapStateToProps =
    (state: any) => (
        {
            locale: state.i18n.locale,
            dataIndicatorsGetOfCategory: (languageCode: string, categoryId: number) => scwApi.endpoints.indicatorsGetOfCategory.select({ "languageCode": languageCode, "categoryId": categoryId })(state),
            dataIndicatorsGetTypes: scwApi.endpoints.indicatorsGetTypes.select(state.i18n.locale)(state),
        }
    );

const mapDispatchToProps =
    (dispatch: Function) => {
        return {
            scwApiIndicatorsGetOfCategory: (languageCode: string, categoryId: number) => dispatch(scwApi.endpoints.indicatorsGetOfCategory.initiate({ languageCode: languageCode, categoryId: categoryId })),
            scwApiIndicatorsGetTypes: (languageCode: string) => dispatch(scwApi.endpoints.indicatorsGetTypes.initiate(languageCode)),
        }
    };

export default connect(mapStateToProps, mapDispatchToProps)(PanelRelatedContent);