import * as React from "react";
import * as _ from "lodash";
import Icon from "@material-ui/core/Icon";
import "./ButtonStandard.css";


type ButtonStandardProps = {
    iconName?: string;
    label?: string;
    onClick?: Function;
}

export default class ButtonStandard extends React.Component<ButtonStandardProps, any> {

    onButtonClick(e: any) {
        if (_.isNil(this.props.onClick))
            return;

        this.props.onClick(e);
    } // onButtonClick

    public render() {
        const loIcon =
            (this.props.iconName)
                ? (
                    <span className="icon">
                        <Icon>{this.props.iconName}</Icon>
                    </span>
                ) : null;

        const loLabel = (
            <span className="label">
                {this.props.label}
            </span>
        );

        return (
            <button className="button-standard" onClick={(e: any) => this.onButtonClick(e)}>
                {loIcon}
                {loLabel}
            </button>
        );
    }
}