import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { setupListeners } from "@reduxjs/toolkit/query";
import { i18nReducer, syncTranslationWithStore } from "react-redux-i18n";
import thunk from "redux-thunk";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { History } from "history";
import { ApplicationState, reducers } from "./";
import { scwApi, scwClientAppApi } from "../services/Api";
import * as _ from "lodash";

export const config = {
    ENVIRONMENT: process.env.REACT_APP_ENV
}

export default function configureStore(history: History, initialState?: ApplicationState) {
    const enhancers = [];

    const windowIfDefined = typeof window === 'undefined' ? null : window as any; // eslint-disable-line @typescript-eslint/no-explicit-any
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const middleware = [
        thunk,
        routerMiddleware(history),
        scwApi.middleware,
        scwClientAppApi.middleware
    ];

    const rootReducer = combineReducers({
        ...reducers,
        i18n: i18nReducer,
        router: connectRouter(history),
        [scwApi.reducerPath]: scwApi.reducer,
        [scwClientAppApi.reducerPath]: scwClientAppApi.reducer,
    });

    const store = createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware as any), ...enhancers)
    );

    syncTranslationWithStore(store);

    setupListeners(store.dispatch);

    return store;
}