import * as React from "react";
import Icon from "@material-ui/core/Icon";
import "./ButtonHeaderBarTop.css";


type ButtonHeaderBarTopProps = {
    active?: boolean,
    label?: string,
    materialIconName?: string,
    scwIconName?: string,
    onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export default class ButtonHeaderBarTop extends React.Component<ButtonHeaderBarTopProps, any> {

    public render() {
        const loMaterialIcon =
            (this.props.materialIconName)
                ? (
                    <span className="icon">
                        <Icon>{this.props.materialIconName}</Icon>
                    </span>
                ) : null;

        const loSCWIcon =
            (this.props.scwIconName)
                ? (
                    <span className={ "scw-icon " + this.props.scwIconName }>
                    </span>
                ) : null;

        const loLabel = (
            <span className="label">
                {this.props.label}
            </span>
        );

        return (
            <button className={`button-header-bar-top ${this.props.active ? "active" : ""}`} onClick={this.props.onClick}>
                {loMaterialIcon}
                {loSCWIcon}
                {loLabel}
            </button>
        );
    }

}