import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Label, Input, Table } from "reactstrap";
import * as _ from "lodash";
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { scwApi } from "../../services/Api";
import ButtonCustomGroup from "../ButtonCustomGroup";
import { GeographyDTO } from "../../services/models/GeographyDTO";
import { GeographyTypeDTO } from "../../services/models/GeographyTypeDTO";

import "./PanelSelectGeographies.css";


type PanelSelectGeographiesProps = {
    dataGeographiesGet: Function,
    dataGeographiesGetTypes: Function,
    locale: string[2],
    onSelectionChanged?: Function,
    scwApiGeographiesGet: Function,
    scwApiGeographiesGetTypes: Function,
    selectedGeoEntityIDs: number[] | null,
};

type PanelSelectGeographiesState = {
    geographies: GeographyDTO[],
    geographyTypes: GeographyTypeDTO[],
    locale: string[2],
    selectedType: GeographyTypeDTO | null,
    selectedGeoEntityIDs: number[] | null | undefined,
}


class PanelSelectGeographies extends React.Component<PanelSelectGeographiesProps, PanelSelectGeographiesState> {

    constructor(props: Readonly<PanelSelectGeographiesProps>) {
        super(props);

        this.state = {
            geographies: [],
            geographyTypes: [],
            locale: "",
            selectedType: null,
            selectedGeoEntityIDs: undefined,
        };
    } // constructor

    areAllGeographiesSelected(): boolean {
        if (_.isNil(this.state.selectedGeoEntityIDs)) {
            return false;
        }

        return _.isEqual(
            _.sortBy(this.state.geographies.map(g => g.geoEntityID)),
            _.sortBy(this.state.selectedGeoEntityIDs)
        ) && (this.state.selectedGeoEntityIDs.length > 0);
    } // areAllGeographiesSelected

    static getDerivedStateFromProps(nextProps: Readonly<PanelSelectGeographiesProps>,
        prevState: PanelSelectGeographiesState):
        Partial<PanelSelectGeographiesState> | null {

        const loGeographiesTypesApiObject = nextProps.dataGeographiesGetTypes(nextProps.locale);

        let nextStateSelectedGeoEntityIDs = prevState.selectedGeoEntityIDs ?? null; // Fallback from undefined to null
        if (!_.isNil(nextProps.selectedGeoEntityIDs) && _.isNil(nextStateSelectedGeoEntityIDs))
            nextStateSelectedGeoEntityIDs = nextProps.selectedGeoEntityIDs;

        // Check selected type of geographies
        const nextStateSelectedType =
            !_.isNil(prevState.selectedType)
                ? prevState.selectedType
                : (
                    !_.isNil(loGeographiesTypesApiObject) &&
                        loGeographiesTypesApiObject.isSuccess &&
                        (loGeographiesTypesApiObject.data.length > 0)
                        ? (loGeographiesTypesApiObject.data[0] as GeographyTypeDTO)
                        : null
                );

        // Ensure geography types are downloaded
        if (!loGeographiesTypesApiObject.isSuccess && !loGeographiesTypesApiObject.isLoading)
            nextProps.scwApiGeographiesGetTypes(nextProps.locale);

        const nextStateGeographyTypes =
            (!_.isNil(loGeographiesTypesApiObject) && loGeographiesTypesApiObject.isSuccess)
                ? loGeographiesTypesApiObject.data as GeographyTypeDTO[]
                : [];

        // Ensure geographies are downloaded
        const loGeographiesApiObject = _.isNil(nextStateSelectedType)
            ? null
            : nextProps.dataGeographiesGet(nextProps.locale, (nextStateSelectedType as GeographyTypeDTO).geoTypeID);

        let nextStateGeographies: GeographyDTO[];
        if (!_.isNil(nextStateSelectedType) && ((!loGeographiesApiObject.isSuccess && !loGeographiesApiObject.isLoading))) {
            nextStateGeographies = [];

            nextProps.scwApiGeographiesGet(nextProps.locale, (nextStateSelectedType as GeographyTypeDTO).geoTypeID);
        } else if (!_.isNil(loGeographiesApiObject) && loGeographiesApiObject.isSuccess) {
            nextStateGeographies = loGeographiesApiObject.data;

            if (_.isNull(nextStateSelectedGeoEntityIDs)) {
                nextStateSelectedGeoEntityIDs = nextStateGeographies.map(aoGeography => aoGeography.geoEntityID);
                if (!_.isNil(nextProps.onSelectionChanged)) {
                    nextProps.onSelectionChanged(nextStateSelectedGeoEntityIDs);
                }
            }
        }
        else
            nextStateGeographies = [];

        return {
            geographies: nextStateGeographies,
            geographyTypes: nextStateGeographyTypes,
            locale: nextProps.locale,
            selectedType: nextStateSelectedType,
            selectedGeoEntityIDs: nextStateSelectedGeoEntityIDs,
        };
    } // getDerivedStateFromProps

    getSelectedGeographiesText(): string | null {
        const liGeographiesCount = this.state.selectedGeoEntityIDs?.length;
        if (_.isNil(liGeographiesCount))
            return null;

        return `${liGeographiesCount} ${liGeographiesCount === 1 ? I18n.t("frontend_text_geography_selected") : I18n.t("frontend_text_geographies_selected")}`;
    } // getSelectedGeographiesText

    isGeographySelected(aoGeography: GeographyDTO): boolean {
        return _.includes(this.state.selectedGeoEntityIDs, aoGeography.geoEntityID);
    } // isGeographySelected

    onClearSelection() {
        this.selectGeographies([]);
    } // onClearSelection

    onGeographyTypeSelected(aoGeographyType: GeographyTypeDTO) {
        this.setState(
            {
                selectedType: aoGeographyType,
            },
            () => {
                this.selectGeographies([]);
            }
        );
    } // onGeographyTypeSelected

    onToggle(e: React.ChangeEvent<HTMLInputElement>, aoGeography: GeographyDTO) {
        let liGeoEntityIDs: number[] = [];

        if (!_.isNil(this.state.selectedGeoEntityIDs)) {
            liGeoEntityIDs =
                _.includes(this.state.selectedGeoEntityIDs, aoGeography.geoEntityID)
                    ? _.filter<number>(this.state.selectedGeoEntityIDs,
                        aoGeoEntityID => (aoGeoEntityID !== aoGeography.geoEntityID))
                    : _.concat<number>(this.state.selectedGeoEntityIDs, aoGeography.geoEntityID);
        }

        this.selectGeographies(liGeoEntityIDs);
    } // onToggle

    onToggleAll() {
        const liGeoEntityIDs = this.state.geographies.map(g => g.geoEntityID);

        this.selectGeographies(liGeoEntityIDs);
    } // onToggleAll

    render() {
        return (
            <div className="panel-select-geographies">
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Table borderless className="panel-select-geographies-summary">
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="panel-title">
                                            {I18n.t("frontend_select_geographies_panel_title")}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="panel-group-type-selector">
                                        {
                                            (this.state.geographyTypes.length > 0) && (
                                                <ButtonCustomGroup
                                                    objects={this.state.geographyTypes}
                                                    onGetCaption={(aoObject: GeographyTypeDTO) => { return aoObject.geoTypeName }}
                                                    onSelected={(aoObject: GeographyTypeDTO) => this.onGeographyTypeSelected(aoObject)}
                                                    selectedObject={this.state.selectedType}
                                                />
                                            )
                                        }
                                        </div>
                                    </td>
                                    <td className="panel-align-right">
                                        <div className="panel-selected-count">
                                            {this.getSelectedGeographiesText() }
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ "width": "100%" }}>
                            <div className="toolbar">
                                <div onClick={() => this.onToggleAll()}>
                                    <span className="select-all">
                                        {I18n.t("frontend_text_all")}                                    
                                    </span>
                                </div>
                                <div onClick={() => this.onClearSelection()}>
                                    <span className="select-none">
                                        {I18n.t("frontend_text_clear_selection")}
                                    </span>
                                </div>
                            </div>

                            <div className="panel-geographies-group">
                                {
                                    _.map(
                                        _.sortBy<GeographyDTO>(
                                            this.state.geographies,
                                            ["geoEntityName"],
                                            ["asc"]
                                        ),
                                        aoGeography => (
                                            <div key={aoGeography.geoEntityCode + "_" + this.isGeographySelected(aoGeography)} className="checkbox-geography">
                                                <Label className="checkbox-label">
                                                    {aoGeography.geoEntityName}
                                                    <Input
                                                        defaultChecked={this.isGeographySelected(aoGeography)}
                                                        onChange={(e) => this.onToggle(e, aoGeography)}
                                                        type="checkbox"
                                                    />
                                                    <span className="checkbox-checkmark"></span>
                                                </Label>
                                            </div>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    } // render

    selectGeographies(aiGeoEntityIDs: number[]) {
        this.setState(
            prevState => ({
                ...prevState,
                selectedGeoEntityIDs: aiGeoEntityIDs,
            }),
            () => {
                if (!_.isNil(this.props.onSelectionChanged)) {
                    this.props.onSelectionChanged(this.state.selectedGeoEntityIDs);
                }
            }
        );
    } // selectGeographies
}

// Link with Redux store

const mapStateToProps =
    (state: any) => (
        {
            locale: state.i18n.locale,
            dataGeographiesGet: (languageCode: string, typeId: number) => scwApi.endpoints.geographiesGet.select({ "languageCode": languageCode, "typeId": typeId })(state),
            dataGeographiesGetTypes: (languageCode: string) => scwApi.endpoints.geographiesGetTypes.select(languageCode)(state),
        }
    );

const mapDispatchToProps =
    (dispatch: Function) => {
        return {
            scwApiGeographiesGet: (languageCode: string, typeId: number) => dispatch(scwApi.endpoints.geographiesGet.initiate({ languageCode, typeId })),
            scwApiGeographiesGetTypes: (languageCode: string) => dispatch(scwApi.endpoints.geographiesGetTypes.initiate(languageCode)),
        }
    };

export default connect(mapStateToProps, mapDispatchToProps)(PanelSelectGeographies);