import * as React from "react";
import { connect } from "react-redux";
import * as _ from "lodash";
import { scwApi } from "../../services/Api";
import { Link } from "react-router-dom";
import Throbber from "../Throbber";
import { IndicatorDTO } from "../../services/models/IndicatorDTO";
import "./PanelIndicatorTile.css";


type PanelIndicatorTileProps = {
    dataIndicatorsGet: any,
    indicatorID?: number,
    indicator?: IndicatorDTO,
    locale: string[2],
    onGetTimestamp?: Function,
    renderCircleShape?: boolean,
    scwApiIndicatorsGet: Function,
};

type PanelIndicatorTileState = {
    indicator: IndicatorDTO | null
}


class PanelIndicatorTile extends React.Component<PanelIndicatorTileProps, PanelIndicatorTileState> {
    constructor(props: Readonly<PanelIndicatorTileProps>) {
        super(props);

        this.state = {
            indicator: null
        }
    } // constructor

    static getDerivedStateFromProps(nextProps: Readonly<PanelIndicatorTileProps>, prevState: PanelIndicatorTileState):
        Partial<PanelIndicatorTileState> | null {

        if (!_.isNull(nextProps.indicator))
            return {
                indicator: nextProps.indicator
            };

        if (!_.isNull(nextProps.indicatorID)) {
            const loScwApiObject = nextProps.dataIndicatorsGet(nextProps.locale, nextProps.indicatorID);

            if (!loScwApiObject.isSuccess && !loScwApiObject.isLoading)
                nextProps.scwApiIndicatorsGet(nextProps.locale, nextProps.indicatorID);
            else if (loScwApiObject.isSuccess && loScwApiObject.data.length > 0)
                return {
                    indicator: loScwApiObject.data[0]
                };
            else
                return {
                    indicator: null
                }
        }
        
        return {
            indicator: null
        };
    } // getDerivedStateFromProps

    render() {
        const lsTimestamp = _.isNil(this.props.onGetTimestamp) ? null : this.props.onGetTimestamp(this.state.indicator);

        return (
            <div className="panel-indicator-tile">
                <div className="panel-indicator-tile-type-and-timestamp-container">
                    {
                        this.props.renderCircleShape
                            && (<span className="panel-indicator-tile-type-shape"></span>)
                    }
                    <span className="panel-indicator-tile-type-name">{(this.state.indicator ? this.state.indicator.typeName : null)}</span>
                    {
                        lsTimestamp
                            && (<span className="panel-indicator-tile-timestamp">{lsTimestamp}</span>)
                    }
                </div>
                <div className="panel-indicator-tile-indicator-container">
                    <Link to={{ pathname: `/dataset`, search: `i=${(this.state.indicator ? this.state.indicator.indicatorID : "")}` }}>
                        <span className="panel-indicator-tile-indicator-name">
                            {(this.state.indicator ? this.state.indicator.name : null)}
                        </span>
                        <span className="panel-indicator-tile-indicator-chevron chevron right">
                        </span>
                    </Link>
                </div>
            </div>
        );
    } // render
}


// Link with Redux store

const mapStateToProps =
    (state: any) => (
        {
            locale: state.i18n.locale,
            dataIndicatorsGet: (languageCode: string, id: number) => scwApi.endpoints.indicatorsGet.select({ "languageCode": languageCode, "id": id })(state),
        }
    );

const mapDispatchToProps =
    (dispatch: Function) => {
        return {
            scwApiIndicatorsGet: (languageCode: string, id: number) => dispatch(scwApi.endpoints.indicatorsGet.initiate({ languageCode: languageCode, id: id })),
        }
    };

export default connect(mapStateToProps, mapDispatchToProps)(PanelIndicatorTile);