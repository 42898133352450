import * as _ from "lodash";
import { ArticleDTO } from "./models/ArticleDTO";
import { CategoryHierarchyDTO } from "./models/CategoryHierarchyDTO";


export default class Utils {

    static getCategoryById(asCategoryId: string | null, aoCategories: CategoryHierarchyDTO[]): CategoryHierarchyDTO | null {
        if (_.isNil(asCategoryId))
            return null;

        const liCategoryId = parseInt(asCategoryId);

        const liIndex =
            _.findIndex<CategoryHierarchyDTO>(
                aoCategories,
                aoCategory => (aoCategory.categoryID === liCategoryId)
            );

        if (liIndex > -1)
            return aoCategories[liIndex];
        else
            return null; // exit forEach
    } // getCategoryById    

    static getCategoriesIdsAsString(aoCategories: CategoryHierarchyDTO[], aoIncludeCategory: CategoryHierarchyDTO): string {
        return aoCategories.concat(aoIncludeCategory).map(aoCategory => aoCategory.categoryID).join(",");
    } // getCategoriesIdsAsString

    static getCategoriesHierarchyURLParams(aoCategoryHierarchy: CategoryHierarchyDTO, aoCurrentResult: any = undefined) {
        if (_.isNil(aoCurrentResult)) {
            aoCurrentResult = {
                parentCategoryIds: "",
                categoryId: "",
            }
        }

        if (
            _.isNil(aoCategoryHierarchy.children)
                || (aoCategoryHierarchy.children.length === 0)
        )
            aoCurrentResult.categoryId = aoCategoryHierarchy.categoryID;
        else {
            aoCurrentResult.parentCategoryIds = (
                aoCurrentResult.parentCategoryIds
                    ? aoCurrentResult.parentCategoryIds + "," + aoCategoryHierarchy.categoryID
                    : aoCategoryHierarchy.categoryID
            );

            aoCurrentResult =
                this.getCategoriesHierarchyURLParams(aoCategoryHierarchy.children[0], aoCurrentResult);
        }

        return aoCurrentResult;
    } // getCategoriesHierarchyURLParams

    static getHierarchyBranchCategoriesIds(aoCategory: CategoryHierarchyDTO): number[] {
        let categoriesIds = [aoCategory.categoryID];
        if (aoCategory.children) {
            aoCategory.children.forEach(
                child => {
                    categoriesIds = categoriesIds.concat(this.getHierarchyBranchCategoriesIds(child));
                }
            );
        }

        return categoriesIds;
    } // getHierarchyBranchCategoriesIds

    static getParentCategoriesByIds(asParentCategoriesIds: string, aoCategories: CategoryHierarchyDTO[]): CategoryHierarchyDTO[] {
        if (_.isNil(asParentCategoriesIds))
            return [];

        const loResult: CategoryHierarchyDTO[] = [];
        let loCategories = aoCategories;

        _.forEach(
            asParentCategoriesIds.split(","),
            asParentCategoryId => {
                const liParentCategoryId = parseInt(asParentCategoryId);
                const liIndex =
                    _.findIndex<CategoryHierarchyDTO>(
                        loCategories,
                        aoCategory => (aoCategory.categoryID === liParentCategoryId)
                    );

                if (liIndex > -1) {
                    const loParentCategory = loCategories[liIndex];

                    loResult.push(loParentCategory);
                    loCategories = loParentCategory.children;
                } else
                    return false; // exit forEach
            }
        );

        return loResult;
    } // getParentCategoriesByIds

    static doesArticleBelongToCategory(aoArticle: ArticleDTO, asCategoryID: string | number): boolean {
        return (
            aoArticle.category
            && aoArticle.category.some(
                aoArticleCategory =>
                    (aoArticleCategory.dataPortalCategoryId == asCategoryID)
                    || (
                        aoArticleCategory.ancestors &&
                        aoArticleCategory.ancestors.some(aoArticleCategoryAncestor => aoArticleCategoryAncestor.dataPortalCategoryId == asCategoryID)
                    )
            )
        );
    } // doesArticleBelongToCategory
}