import * as React from "react";
import * as _ from "lodash";
import { Button, ButtonGroup } from "reactstrap";

import "./ButtonCustomGroup.css";


type ButtonCustomGroupProps = {
    className?: string;
    objects: any[];
    onGetCaption?: Function;
    onSelected?: Function;
    selectedObject?: any;
}

type ButtonCustomGroupState = {
    selectedObject: any
}

export default class ButtonCustomGroup extends React.Component<ButtonCustomGroupProps, ButtonCustomGroupState> {

    constructor(props: Readonly<ButtonCustomGroupProps>) {
        super(props);

        this.state = {
            selectedObject:
                _.isNil(props.selectedObject)
                    ? (
                        (props.objects.length > 0)
                            ? props.objects[0]
                            : undefined
                    ) : props.selectedObject,
        };
    } // constructor

    static getDerivedStateFromProps(nextProps: Readonly<ButtonCustomGroupProps>,
        prevState: ButtonCustomGroupState): Partial<ButtonCustomGroupState> | null {
        return {
            selectedObject: nextProps.selectedObject
        }
    }

    getCaption(aoObject: any) {
        return _.isNil(this.props.onGetCaption) ? null : this.props.onGetCaption(aoObject);
    } // getCaption

    isButtonSelected(aoButton: any) {
        return _.isEqual(aoButton, this.state.selectedObject);
    } // isButtonSelected

    onButtonClick(e: React.MouseEvent<any, MouseEvent>, aoButton: any) {
        e.stopPropagation();

        if (_.isNil(this.props.onSelected))
            return;

        this.props.onSelected(aoButton);
    } // onButtonClick

    render() {
        return (
            <ButtonGroup className={`button-custom-group${this.props.className ? " " + this.props.className : ""}`}>
                {
                    _.map(
                        this.props.objects,
                        (aoObject, aiIndex) => {
                            return (
                                <Button
                                    className={this.isButtonSelected(aoObject) ? "button-custom-group-selected" : ""}
                                    key={aiIndex}
                                    onClick={(e) => { this.onButtonClick(e, aoObject); }}
                                >
                                    {this.getCaption(aoObject)}
                                </Button>
                            );
                        }
                    )
                }
            </ButtonGroup>
        );
    } // render
}