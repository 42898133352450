import * as React from "react";
import { connect } from "react-redux";
import * as _ from "lodash";
import { scwApi } from "../../services/Api";
import PanelInfographic from "./PanelInfographic";
import { UIInfographicConfigurationDTO } from "../../services/models/UIInfographicConfigurationDTO";

import "./PanelInfographics.css";


type PanelInfographicsProps = {
    groupId: string,
    dataConfigurationGetInfographics: any,
    scwApiConfigurationGetInfographics: Function,
}

type PanelInfographicsState = {
    UIInfographicsConfiguration: UIInfographicConfigurationDTO[]
}


class PanelInfographics extends React.Component<PanelInfographicsProps, PanelInfographicsState> {

    constructor(props: Readonly<PanelInfographicsProps>) {
        super(props);

        this.state = {
            UIInfographicsConfiguration: [],
        }
    } // constructor

    static getDerivedStateFromProps(nextProps: Readonly<PanelInfographicsProps>,
        prevState: PanelInfographicsState): Partial<PanelInfographicsState> | null {
        const loScwApiObject = nextProps.dataConfigurationGetInfographics(nextProps.groupId);

        if (!loScwApiObject.isSuccess && !loScwApiObject.isLoading) {
            nextProps.scwApiConfigurationGetInfographics(nextProps.groupId);

            if (_.isEmpty(prevState.UIInfographicsConfiguration))
                return null;
            else
                return {
                    UIInfographicsConfiguration: [],
                }
        }

        const nextStateUIInfographicsConfiguration =
            _.sortBy<UIInfographicConfigurationDTO>(
                loScwApiObject.data,
                ["displayOrder", "indicatorID"],
                ["asc", "asc"]
            );
        if (_.isEqual(prevState.UIInfographicsConfiguration, nextStateUIInfographicsConfiguration))
            return null;
        else
            return {
                UIInfographicsConfiguration: nextStateUIInfographicsConfiguration,
            };
    } // getDerivedStateFromProps

    render() {
        if (_.isEmpty(this.state.UIInfographicsConfiguration))
            return null;

        return (
            <div className="infographics-container">
                {
                    this.state.UIInfographicsConfiguration.map(
                        (aoUIInfographicConfiguration, aiIndex) => {
                            return (
                                <PanelInfographic key={aiIndex} indicatorId={aoUIInfographicConfiguration.indicatorID}/>
                            );
                        }
                    )
                }
            </div>
        );
    } // render

}

// Link with Redux store

const mapStateToProps =
    (state: any) => (
        {
            dataConfigurationGetInfographics: (groupId: string) => scwApi.endpoints.configurationGetInfographics.select(groupId)(state),
        }
    );

const mapDispatchToProps =
    (dispatch: Function) => {
        return {
            scwApiConfigurationGetInfographics: (groupId: string) => dispatch(scwApi.endpoints.configurationGetInfographics.initiate(groupId)),
        }
    };

export default connect(mapStateToProps, mapDispatchToProps)(PanelInfographics);