import * as React from "react";
import "./Heading.css";


type HeadingProps = {
    align?: string;
    className?: string;
    description?: string;
    title?: string;
}

export default class Heading extends React.Component<HeadingProps, any> {

    public render() {
        const loTitle =
            (this.props.title)
                ? (
                    <span className="title">
                        {this.props.title}
                    </span>
                ) : null;

        const loDescription =
            (this.props.description)
                ? (
                    <span className="description">
                        {this.props.description}
                    </span>
                ) : null;

        return (
            <div className={`heading${(this.props.align === "left" ? " align-left" : "")}${(this.props.className ? " " + this.props.className : "")}`}>
                {loTitle}
                {loDescription}
            </div>
        );
    }
}