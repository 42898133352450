import * as React from "react";
import "./Throbber.css";


class Throbber extends React.Component<any, any> {
    render() {
        return (
            <div className="throbber-ripple">
                <div></div>
                <div></div>
            </div >
        );
    } // render
}

export default Throbber;