import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Table } from "reactstrap";
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import * as _ from "lodash";
import { scwApi } from "../../services/Api";
import { DatasetDTO } from "../../services/models/DatasetDTO";
import { DatasetIndicatorGroupDTO } from "../../services/models/DatasetIndicatorGroupDTO";
import { IndicatorDTO } from "../../services/models/IndicatorDTO";
import PanelSelectIndicator from "./PanelSelectIndicator";

import "./PanelSelectData.css";


type PanelSelectDataProps = {
    allowClear?: boolean;
    allowSearch: boolean;
    allowSelectOnlyMeasures?: boolean;
    caption?: string;
    caption2?: string
    categoryId?: number;
    dataDatasetsGetOfCategory: Function,
    dataIndicatorsGet: Function,
    dataIndicatorsGetOfCategory: Function,
    dataIndicatorsGetOfDataset: Function,
    dataIndicatorsSearch: Function,
    dataset?: DatasetDTO | undefined,
    datasetIndicators?: DatasetIndicatorGroupDTO[] | undefined,
    datasetContext?: DatasetDTO | undefined,
    locale: string[2],
    indicator?: IndicatorDTO | undefined,
    multipleSelection?: boolean,
    onClearSelection?: any,
    onSelectedItems?: (aoItems: (DatasetDTO | DatasetIndicatorGroupDTO | IndicatorDTO)[]) => void,
    scwApiDatasetsGetOfCategory: Function,
    scwApiIndicatorsGet: Function,
    scwApiIndicatorsGetOfCategory: Function,
    scwApiIndicatorsGetOfDataset: Function,
    scwApiIndicatorsSearch: Function,
};

type PanelSelectDataState = {
    allowSearch: boolean;
    categoryId?: number;
    datasets: DatasetDTO[];
    dataset: DatasetDTO | undefined;
    indicators: IndicatorDTO[];
    indicatorsOfDataset: DatasetIndicatorGroupDTO[];
    indicator?: IndicatorDTO | undefined;
    isSelectionExpanded: boolean;
    locale: string[2];
    panelTitle: string | null;
    searchText: string;
    selectedItems: (DatasetDTO | DatasetIndicatorGroupDTO | IndicatorDTO)[] | undefined;
}

class PanelSelectData extends React.Component<PanelSelectDataProps, PanelSelectDataState> {

    inputRef: any = undefined;

    constructor(props: Readonly<PanelSelectDataProps>) {
        super(props);

        this.state = {
            allowSearch: props.allowSearch,
            datasets: [],
            dataset: undefined,
            indicators: [],
            indicatorsOfDataset: [],
            indicator: props.indicator,
            isSelectionExpanded: true,
            locale: props.locale,
            panelTitle: null,
            searchText: "",
            selectedItems: undefined,
        };
    } // constructor

    static getDerivedStateFromProps(nextProps: Readonly<PanelSelectDataProps>, prevState: PanelSelectDataState):
        Partial<PanelSelectDataState> | null {

        // Load indicators & datasets
        let nextStateDatasets: DatasetDTO[] = [];
        let nextStateIndicators: IndicatorDTO[] = [];
        let nextStateIndicatorsOfDataset: DatasetIndicatorGroupDTO[] = [];
        let nextStateSelectedItems: (DatasetDTO | DatasetIndicatorGroupDTO | IndicatorDTO)[] | undefined = prevState.selectedItems;

        if (!_.isNil(nextProps.datasetContext)) {
            // Load indicators of dataset
            const loIndicatorsGetOfDatasetApiObject =
                nextProps.dataIndicatorsGetOfDataset(nextProps.locale, nextProps.datasetContext.datasetID);

            if (
                !loIndicatorsGetOfDatasetApiObject.isSuccess
                && !loIndicatorsGetOfDatasetApiObject.isLoading
            )
                nextProps.scwApiIndicatorsGetOfCategory(nextProps.locale, nextProps.categoryId);

            if (loIndicatorsGetOfDatasetApiObject.isSuccess) {
                nextStateIndicatorsOfDataset = loIndicatorsGetOfDatasetApiObject.data;                

                if (_.isUndefined(nextStateSelectedItems)) {
                    if (!_.isUndefined(nextProps.datasetIndicators)) {
                        nextStateSelectedItems = nextProps.datasetIndicators.filter(aoItem => nextStateIndicatorsOfDataset.some(_aoItem => _aoItem.datasetIndicatorGroupID === aoItem.datasetIndicatorGroupID));
                    }
                    else {
                        // If no indicators provided but we got dataset's ones loaded, select all
                        nextStateSelectedItems = nextStateIndicatorsOfDataset.concat([]);

                        if (!_.isNil(nextProps.onSelectedItems)) {
                            nextProps.onSelectedItems(nextStateSelectedItems);
                        }
                    }
                }
            }
        }
        else if (!_.isNil(nextProps.categoryId)) {
            // Load category indicators
            const loIndicatorsGetOfCategoryApiObject =
                nextProps.dataIndicatorsGetOfCategory(nextProps.locale, nextProps.categoryId);

            if (
                !loIndicatorsGetOfCategoryApiObject.isSuccess
                && !loIndicatorsGetOfCategoryApiObject.isLoading
            )
                nextProps.scwApiIndicatorsGetOfCategory(nextProps.locale, nextProps.categoryId);

            if (loIndicatorsGetOfCategoryApiObject.isSuccess)
                nextStateIndicators = loIndicatorsGetOfCategoryApiObject.data;

            // Load datasets
            if (!nextProps.allowSelectOnlyMeasures) {
                const loDatasetsGetOfCategoryApiObject =
                    nextProps.dataDatasetsGetOfCategory(nextProps.locale, nextProps.categoryId);

                if (
                    !loDatasetsGetOfCategoryApiObject.isSuccess
                    && !loDatasetsGetOfCategoryApiObject.isLoading
                )
                    nextProps.scwApiDatasetsGetOfCategory(nextProps.locale, nextProps.categoryId);

                if (loDatasetsGetOfCategoryApiObject.isSuccess)
                    nextStateDatasets = loDatasetsGetOfCategoryApiObject.data;
            }
        }
        else if (nextProps.allowSearch) {
            // Search through indicators
            const loIndicatorsSearchApiObject =
                nextProps.dataIndicatorsSearch(nextProps.locale, prevState.searchText);

            if (loIndicatorsSearchApiObject.isSuccess)
                nextStateIndicators = loIndicatorsSearchApiObject.data;
        }

        // Establish title of the panel
        let nextStatePanelTitle: string;
        if (nextProps.caption)
            nextStatePanelTitle = nextProps.caption;
        else if (nextProps.allowSelectOnlyMeasures)
            nextStatePanelTitle = I18n.t("frontend_select_data_panel_measures_only_title");
        else 
            nextStatePanelTitle = I18n.t("frontend_select_data_panel_title");

        return {
            categoryId: nextProps.categoryId,
            dataset: nextProps.dataset,
            datasets: nextStateDatasets,
            indicator: nextProps.indicator,
            indicators: nextStateIndicators,
            indicatorsOfDataset: nextStateIndicatorsOfDataset,
            locale: nextProps.locale,
            panelTitle: nextStatePanelTitle,
            selectedItems: nextStateSelectedItems,
        };
    } // getDerivedStateFromProps

    getSelectedItems(): (DatasetDTO | DatasetIndicatorGroupDTO | IndicatorDTO)[] | undefined {
        if (this.props.datasetContext) {
            if (this.state.selectedItems) {
                return this.state.selectedItems;
            }
        }
        else {
            if (this.state.dataset)
                return [this.state.dataset];
            else if (this.state.indicator)
                return [this.state.indicator];
        }

        return undefined;
    } // getSelectedItems

    getSummaryDescription(): string {
        if (this.props.multipleSelection) {
            const liCount = (this.state.selectedItems ?? []).length;

            return `${liCount} ${liCount === 1 ? I18n.t("frontend_text_measure_selected") : I18n.t("frontend_text_measures_selected")}`;
        }

        return (
            _.isNil(this.state.indicator)
                ? (
                    _.isNil(this.state.dataset)
                        ? ""
                        : this.state.dataset.name
                )
                : this.state.indicator.name
        );
    } // getSummaryDescription

    onClearSelection() {
        if (_.isNil(this.props.onClearSelection))
            return;

        this.setState({
            isSelectionExpanded: false,
        }, () => {
            this.props.onClearSelection();
        });
    } // onClearSelection

    onInputChange(e: any) {
        this.setState({
            searchText: e.target.value,
        });
    } // onInputChange

    onInputKeyDown(e: any) {
        if (e.keyCode == 13)
            this.onSearchClick();
    } // onInputKeyDown

    onSearchClick() {
        if (_.isEmpty(this.state.searchText))
            return;

        this.props.scwApiIndicatorsSearch(this.props.locale, this.state.searchText);
        // TO DO: datasets search, eventually introduce new method for searching of indicators and datasets
    } // onSearchClick

    onSelectClick() {
        this.setState(
            prevState => (
                {
                    isSelectionExpanded: !prevState.isSelectionExpanded,
                }
            )
        );
    } // onSelectClick

    onPanelSelectIndicatorSelectedItems(aoItemArr: (DatasetDTO | DatasetIndicatorGroupDTO | IndicatorDTO)[]) {
        this.setState(
            (prevState) => ({
                ...prevState,
                selectedItems: aoItemArr,
            }), () => {
                if (this.props.onSelectedItems)
                    this.props.onSelectedItems(aoItemArr);
            }
        );
    } // onPanelSelectIndicatorSelectedItems

    render() {
        return (
            <div className="panel-select-data">
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Table borderless>
                            <tbody>
                                <tr>
                                    <td>
                                        <span className="panel-title">
                                            { this.state.panelTitle }
                                        </span>
                                    </td>
                                    <td className={this.props.multipleSelection ? "panel-title-align-right" : ""}>
                                        <span className={this.props.multipleSelection ? "panel-title-selected-count" : "panel-title-indicator-name"}>
                                            { this.getSummaryDescription() }
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PanelSelectIndicator
                            datasets={this.state.datasets}
                            indicators={this.state.indicators}
                            indicatorsOfDataset={this.state.indicatorsOfDataset}
                            multipleSelection={this.props.multipleSelection}
                            onSelectedItems={(aoItemArr: any) => this.onPanelSelectIndicatorSelectedItems(aoItemArr)}
                            selectedItems={this.getSelectedItems()}
                        />
                    </AccordionDetails>
                </Accordion>
                {
                    this.props.caption2 && (
                        <div className="additional-data-advisory">
                            {this.props.caption2}
                        </div>
                    )
                }
            </div>
        );
    } // render

}


// Link with Redux store

const mapStateToProps =
    (state: any) => (
        {
            locale: state.i18n.locale,
            dataDatasetsGetOfCategory: (languageCode: string, categoryId: number) => scwApi.endpoints.datasetsGetOfCategory.select({ "languageCode": languageCode, "categoryId": categoryId })(state),
            dataIndicatorsGet: (languageCode: string, id: number) => scwApi.endpoints.indicatorsGet.select({ "languageCode": languageCode, "id": id })(state),
            dataIndicatorsGetOfCategory: (languageCode: string, categoryId: number) => scwApi.endpoints.indicatorsGetOfCategory.select({ "languageCode": languageCode, "categoryId": categoryId })(state),
            dataIndicatorsGetOfDataset: (languageCode: string, datasetId: number) => scwApi.endpoints.indicatorsGetOfDataset.select({ "languageCode": languageCode, "datasetId": datasetId })(state),
            dataIndicatorsSearch: (languageCode: string, searchText: string) => scwApi.endpoints.indicatorsSearch.select({ "languageCode": languageCode, "searchText": searchText })(state),
        }
    );

const mapDispatchToProps =
    (dispatch: Function) => {
        return {
            scwApiDatasetsGetOfCategory: (languageCode: string, categoryId: number) => dispatch(scwApi.endpoints.datasetsGetOfCategory.initiate({ languageCode, categoryId })),
            scwApiIndicatorsGet: (languageCode: string, id: number) => dispatch(scwApi.endpoints.indicatorsGet.initiate({ languageCode, id })),
            scwApiIndicatorsGetOfCategory: (languageCode: string, categoryId: number) => dispatch(scwApi.endpoints.indicatorsGetOfCategory.initiate({ languageCode, categoryId })),
            scwApiIndicatorsGetOfDataset: (languageCode: string, datasetId: number) => dispatch(scwApi.endpoints.indicatorsGetOfDataset.initiate({ languageCode: languageCode, datasetId: datasetId })),
            scwApiIndicatorsSearch: (languageCode: string, searchText: string) => dispatch(scwApi.endpoints.indicatorsSearch.initiate({ languageCode: languageCode, searchText: searchText })),
        }
    };

export default connect(mapStateToProps, mapDispatchToProps)(PanelSelectData);