import * as React from "react";
import { Link } from "react-router-dom";
import { ListGroup, ListGroupItem } from "reactstrap";
import * as _ from "lodash";
import { IndicatorDTO } from "../../services/models/IndicatorDTO";
import LinkSee from "../LinkSee";
import "./PanelSearchResult.css";


type PanelSearchResultProps = {
    indicators?: IndicatorDTO[],
    isVisible: boolean,
    onClickItem?: any,
    searchResult?: IndicatorDTO[],
}

type PanelSearchResultState = {
    isShowingAll: boolean,
}

class PanelSearchResult extends React.Component<PanelSearchResultProps, PanelSearchResultState> {
    private rowLimit = 13;

    constructor(props: Readonly<PanelSearchResultProps>) {
        super(props);

        this.state = {
            isShowingAll: false,
        }
    } // constructor

    onClickItem(e: any, aoItem: any) {
        if (_.isNil(this.props.onClickItem))
            return;

        this.props.onClickItem(aoItem);
    } // onClickItem

    onLinkSeeClick(asMode: string) {
        this.setState({ isShowingAll: (asMode === "all") });
    } // onLinkSeeClick

    render() {
        if (
            !this.props.isVisible
            || (
                _.isEmpty(this.props.searchResult)
                && _.isEmpty(this.props.indicators)
            )
        )
            return null;

        return (
            <div className="panel-search-result-list-container">
                <ListGroup className="panel-search-result-list">
                    {
                        // Search result
                        !_.isNil(this.props.indicators)
                        && _.map(
                            _.take(
                                this.props.indicators,
                                (this.state.isShowingAll ? this.props.indicators.length : this.rowLimit)
                            ),
                            (aoItem, aiIndex) => {
                                return (
                                    <ListGroupItem key={aiIndex} onClick={(e) => this.onClickItem(e, aoItem)}>
                                        <span className="indicator-name">
                                            {aoItem.name}
                                        </span>
                                        <span className="indicator-type-name">
                                            {aoItem.typeName}
                                        </span>
                                    </ListGroupItem>
                                );
                            }
                        )
                    }
                    {
                        // Search result
                        !_.isNil(this.props.searchResult)
                            && _.map(
                                _.take(
                                    this.props.searchResult,
                                    (this.state.isShowingAll ? this.props.searchResult.length : this.rowLimit)
                                ),
                                (aoItem, aiIndex) => {
                                    return (
                                        <ListGroupItem key={aiIndex}>
                                            <Link to={{ pathname: `/dataset`, search: `i=${aoItem.indicatorID}` }}>
                                                <span className="indicator-name">
                                                    {aoItem.name}
                                                </span>
                                            </Link>
                                            <span className="indicator-type-name">
                                                {aoItem.typeName}
                                            </span>
                                        </ListGroupItem>
                                    );
                                }
                            )
                    }
                    {
                        // Search result
                        !_.isNil(this.props.searchResult)
                            && (this.props.searchResult.length > this.rowLimit)
                            && (
                                <ListGroupItem className="list-group-item-see-all">
                                    <LinkSee
                                        mode={this.state.isShowingAll ? "less" : "all"}
                                        onClick={(asMode: string) => this.onLinkSeeClick(asMode)}
                                    />
                                </ListGroupItem>
                            )
                    }
                </ListGroup>
            </div>
        );
    } // render
}

export default PanelSearchResult;