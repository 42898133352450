import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import * as _ from "lodash";
import { Table } from "reactstrap";
import "./PanelArticlesCategory.css";


type PanelArticlesCategoryProps = {
    locale: string[2],
    enableOverviewLink: boolean,
};

type PanelArticlesCategoryState = {
}

class PanelArticlesCategory extends React.Component<PanelArticlesCategoryProps, PanelArticlesCategoryState> {
    constructor(props: Readonly<PanelArticlesCategoryProps>) {
        super(props);

        this.state = {};
    } // constructor

    render() {
        return (
            <div className="category-container">
                <div className="category-main-bar"></div>
                <Table borderless>
                    <tbody>
                        <tr>
                            <td className="cell-category">
                                <span className="category-name">
                                    {I18n.t("frontend_text_Articles")}
                                </span>
                                {
                                    (
                                        this.props.enableOverviewLink && (
                                            <Link to={{ pathname: `/articles` }}>
                                                <span className="category-overview">
                                                    {I18n.t("frontend_panel_category_text_Overview")}
                                                </span>
                                            </Link>
                                        )
                                    )
                                }
                            </td>
                            <td className="cell-category-subcategories">
                                <div className="category-subcategory">
                                    <Link to={{ pathname: `/articles` }}>
                                        <span className="subcategory-name">
                                            {I18n.t("frontend_text_Articles")}
                                        </span>
                                    </Link>

                                    <span className="subcategory-chevron chevron right">
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    } // render

}

// Link with Redux store

const mapStateToProps =
    (state: any) => (
        {
            locale: state.i18n.locale,
        }
    );

export default connect(mapStateToProps)(PanelArticlesCategory);