import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Table } from "reactstrap";
import { HashLink } from 'react-router-hash-link';
import * as _ from "lodash";
import "./HeaderBarBottom.css";
// @ts-ignore
import SCWLogoSVG from "../assets/img/logo-large.svg";
import ButtonStandard from "./ButtonStandard";


type HeaderBottomProps = RouteComponentProps<{}> & {
    locale: string[2]
}



class HeaderBarBottom extends React.Component<HeaderBottomProps, any> {


    render() {

        const urlEng = <a href="https://socialcare.wales" target="_blank">
            <img src={SCWLogoSVG} className="logo" alt="Social Care Wales" />
        </a>;

        const urlCy = <a href="https://gofalcymdeithasol.cymru/" target="_blank">
            <img src={SCWLogoSVG} className="logo" alt="Gofal Cymdeithasol Cymru" />
        </a>;


        const url  = () => {
            switch (this.props.locale) {

                case "en": return urlEng;
                case "cy": return urlCy;

            }
        }


        return (
            <div className="header-bar-bottom">
                <Table borderless className="table">
                    <tbody>
                        <tr>
                            <td className="cell-logo">
                                {url()}                                
                            </td>
                            <td className="cell-title">
                                <span className="title">
                                    {I18n.t("frontend_text_NationalSocialCareDataPortalForWales")}
                                </span>
                            </td>
                            <td className="cell-button-search">
                                <HashLink
                                    to="/#panel-search-input"
                                    scroll={
                                        (e) => {
                                            const loPanelSearch = document.getElementById("panel-search");
                                            if (!_.isNil(loPanelSearch))
                                                loPanelSearch.scrollIntoView();
                                        }
                                    }
                                >
                                    <ButtonStandard
                                        iconName="search"
                                        label={I18n.t("frontend_button_Search")}
                                    />
                                </HashLink>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    }

};


const mapStateToProps = (state: any) => ({ locale: state.i18n.locale });

export default connect(mapStateToProps)(withRouter(HeaderBarBottom));