import * as React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Link } from "react-router-dom";
import { Collapse, Container, Navbar, NavbarToggler, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import * as _ from "lodash";
import { scwApi } from "../services/Api";
import { CategoryHierarchyDTO } from "../services/models/CategoryHierarchyDTO";
import "./NavMenu.css";


type NavMenuProps = {
    dataCategory: any,
    locale: string[2],
}

type NavMenuState = {
    isOpen: boolean,
    categoriesMajor: CategoryHierarchyDTO[],
    categoriesMinor: CategoryHierarchyDTO[],
}

class NavMenu extends React.Component<NavMenuProps, NavMenuState> {
    constructor(props: Readonly<NavMenuProps>) {
        super(props);

        this.state = {
            isOpen: false,
            categoriesMajor: [],
            categoriesMinor: [],
        };
    } // constructor

    static getDerivedStateFromProps(nextProps: Readonly<NavMenuProps>,
        prevState: NavMenuState): Partial<NavMenuState> | null {

        if (!nextProps.dataCategory.isSuccess)
            return {
                categoriesMajor: [],
                categoriesMinor: [],
            };

        // Order categories, prepare to split
        const loCategoryArr =
            _.orderBy<CategoryHierarchyDTO>(
                nextProps.dataCategory.data,
                ["displayOrder", "categoryName"],
                ["asc", "asc"]
            );

        return {
            categoriesMajor:
                _.take(
                    loCategoryArr,
                    4
                ),
            categoriesMinor:
                _.drop(
                    loCategoryArr,
                    4
                ),
        };
    } // getDerivedStateFromProps

    render() {
        return (
            <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom mb-3 navbar-custom" light>
                <Container className="navbar-container">
                    <NavbarToggler onClick={this.toggle} className="mr-2"/>
                    <Collapse className="d-sm-inline-flex flex-sm-row justify-content-center" isOpen={this.state.isOpen} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <NavLink tag={Link} to="/">{I18n.t("frontend_menuItem_Home")}</NavLink>
                            </NavItem>
                            {
                                // Build main categories menu items
                                this.state.categoriesMajor.map(
                                    (category: CategoryHierarchyDTO) => {
                                        return (
                                            <NavItem key={category.categoryID}>
                                                <NavLink tag={Link} to={{
                                                    pathname: ((category.children.length > 0) ? `/category` : `/dataset`), search: `c=${category.categoryID}`
                                                }}>{category.categoryName}</NavLink>
                                            </NavItem>
                                        );
                                    }
                                )
                            }
                            {
                                // Build "More" category menu item
                                (this.state.categoriesMinor.length > 0) && (
                                    <UncontrolledDropdown inNavbar nav>
                                        <DropdownToggle caret nav>
                                            {I18n.t("frontend_menuItem_More")}
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            {
                                                this.state.categoriesMinor.map(
                                                    (aoCategory) => {
                                                        return (
                                                            <DropdownItem key={aoCategory.categoryID} tag={Link} to={{ pathname: ((aoCategory.children.length > 0) ? `/category` : `/dataset`), search: `c=${aoCategory.categoryID}` }}>
                                                                {aoCategory.categoryName}
                                                            </DropdownItem>
                                                        );
                                                    }
                                                )
                                            }
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                )
                            }
                            {
                                
                                
                                <NavItem>
                                    <NavLink tag={Link} to="/articles">{I18n.t("frontend_menuItem_Articles")}</NavLink>
                                </NavItem>
                                
                            }
                            <NavItem>
                                <NavLink tag={Link} to="/about">{I18n.t("frontend_menuItem_About")}</NavLink>
                            </NavItem>
                        </ul>
                    </Collapse>
                </Container>
            </Navbar>
        );
    } // render

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    } // toggle
}

// Link with Redux store

const mapStateToProps =
    (state: any) => (
        {
            dataCategory: scwApi.endpoints.categoriesGetHierarchyAll.select(state.i18n.locale)(state),
            locale: state.i18n.locale,
        }
    );

export default connect(mapStateToProps)(NavMenu);