import * as React from "react";
import { connect } from "react-redux";
import * as _ from "lodash";
import { scwApi } from "../../services/Api";
import { CategoryHierarchyDTO } from "../../services/models/CategoryHierarchyDTO";
import PanelArticlesCategory from "../panel/PanelArticlesCategory";
import Throbber from "../Throbber";
import "./PanelMainDataCategories.css";


type PanelMainDataCategoriesProps = {
    addArticlesCategory?: boolean,
    dataCategory: any,
    locale: string[2],    
    onRenderCategory: (aoCategory: CategoryHierarchyDTO) => JSX.Element | null;
    title: string;
};

type PanelMainDataCategoriesState = {
    categoriesMajor: CategoryHierarchyDTO[],
};


class PanelMainDataCategories extends React.Component<PanelMainDataCategoriesProps, PanelMainDataCategoriesState> {

    constructor(props: Readonly<PanelMainDataCategoriesProps>) {
        super(props);

        this.state = {
            categoriesMajor: [],
        }
    } // constructor

    static getDerivedStateFromProps(nextProps: Readonly<PanelMainDataCategoriesProps>,
        prevState: PanelMainDataCategoriesState): Partial<PanelMainDataCategoriesState> | null {

        const loCategories =
            _.orderBy(
                nextProps.dataCategory.data,
                ["displayOrder", "categoryName"],
                ["asc", "asc"]
            );

        return {
            categoriesMajor: loCategories,
        };
    } // getDerivedStateFromProps


    render() {
        if ((!this.props.dataCategory) || this.props.dataCategory.isLoading)
            return <Throbber/>;

        return (
            <div className="categories-container">
                <div className="categories-title">
                    <span>
                        {this.props.title}
                    </span>
                </div>
                <div className="categories-major">
                    {
                        this.state.categoriesMajor.map(
                            (aoCategory) => {
                                return (
                                    <div key={aoCategory.categoryID} className="panel">
                                        {
                                            this.props.onRenderCategory(aoCategory)
                                        }
                                    </div>
                                );
                            }
                        )
                    }

                    {
                        this.props.addArticlesCategory && <PanelArticlesCategory enableOverviewLink={true} />
                    }
                </div>
            </div>
        );
    } // render

}

// Link with Redux store

const mapStateToProps =
    (state: any) => (
        {
            dataCategory: scwApi.endpoints.categoriesGetHierarchyAll.select(state.i18n.locale)(state),
            locale: state.i18n.locale,
        }
    );

export default connect(mapStateToProps)(PanelMainDataCategories);